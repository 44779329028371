export enum CfopEnum {
    CFOP_5102 = "CFOP_5102",
    CFOP_5405 = "CFOP_5405",   
}

export function CfopText(key: string) : string {
    const cfop = CfopEnum[key];
    switch (cfop) {
        case "CFOP_5102":
            return "CFOP_5102";
        case "CFOP_5405":
            return "CFOP_5405";
    }
}

export function CfopValue(key: string) : string {
    const cfop = CfopEnum[key];
    switch (cfop) {
        case "CFOP_5102":
            return '5102';
        case "CFOP_5405":
            return '5405';
    }
}

export function CfopKeyByValue(value) {
    switch (value) {
        case '5102':
            return 'CFOP_5102';
        case 5102:
            return 'CFOP_5102';
        case '5405':
            return 'CFOP_5405';
        case 5405:
            return 'CFOP_5405';
    }
}