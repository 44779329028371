export enum TypeNFSaleEnum {
    CFE = "CFE",
    NFCE = "NFCE",
}

export function TypeNFSaleText(type: TypeNFSaleEnum | string) {
    switch (type) {
        case TypeNFSaleEnum.CFE:
            return "CFe";
        case TypeNFSaleEnum.NFCE:
            return "NFCe";
        default:
            throw new Error(`Not implemented for ${type}`);
    }
}