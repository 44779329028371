import * as Sentry from '@sentry/react';
import axios from 'axios';
import {
    ENUM_SHORTCUT,
    OrderByProductsCategoriesEnum,
    PermissionsEnum,
} from 'erva-doce-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Button, { ButtonStyle } from '../../components/Button';
import FieldTextSearch from '../../components/FieldTextSearch';
import LoadError from '../../components/LoadError';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import { IconAdd, IconAdd2x } from '../../components/images';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as ProductsCategoriesService from '../../services/ProductsCategoriesService';
import { getProductsCategoriesFormRoute } from './ProductCategoryForm';

export default function ProductsCategories() {
    const [productsCategories, setProductsCategories] = useState({});
    const [productsCategoriesLoading, setProductsCategoriesLoading] = useState(null);
    const [filter, setFilter] = useState({
        order: OrderByProductsCategoriesEnum.PRODUCT_CATEGORIES_NAME_ASC,
        search: null,
        segmentations: [],
        page: 0
    });
    const inputSearchRef = useRef();
    const {
        addHotkey,
        removeHotkey,
        user,
    } = useContext(EnvironmentContext);

    const canAdd = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_PRODUCTS);
    const navigate = useNavigate();
    const handleRowClick = (route) => navigate(route);

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        fetchProductsCategories();
    }, [filter]);

    useEffect(() => {
        const shortcutSearchSecondary = addHotkey(ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY, () => {
            inputSearchRef.current?.focus();
        });

        const shortcutCreatePrimary = addHotkey(ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY, () => {
            navigate(getProductsCategoriesFormRoute());
        });

        return () => {
            removeHotkey(shortcutSearchSecondary);
            removeHotkey(shortcutCreatePrimary);
        };
    }, []);

    async function fetchProductsCategories() {
        if (productsCategoriesLoading?.cancel) {
            productsCategoriesLoading.cancel();
        }
        try {
            const cancelToken = axios.CancelToken.source().token;
            setProductsCategoriesLoading(cancelToken);
            const response = await ProductsCategoriesService.getProductsCategories(filter.page, filter.search, filter.order,
                cancelToken);
            setProductsCategories(response);
            setProductsCategoriesLoading(null);
        } catch (e) {
            if (axios.isCancel(e)) {
                console.debug('Request cancelled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
                setProductsCategoriesLoading(e);
                setProductsCategories(null);
            }
        }
    }

    function renderTable() {
        if (productsCategoriesLoading && productsCategoriesLoading instanceof Error) {
            return (
                <LoadError
                    // I18N
                    message={'Não foi possível carregar os cargos'}
                    onTryAgain={fetchProductsCategories}
                />
            );
        }

        let rows = [];
        // noinspection JSUnresolvedReference
        const records = productsCategories?.records ?? [];
        for (const s of records) {
            rows.push(
                <tr key={s.uuid} onClick={() => handleRowClick(getProductsCategoriesFormRoute(s.uuid))}>
                    <td>
                        {s.name}
                    </td>
                </tr>
            );
        }

        return (
            <>
                <Table
                    currentSort={filter.order}
                    columns={[
                        {
                            // I18N
                            name: 'Nome da categoria',
                            sortAsc: OrderByProductsCategoriesEnum.PRODUCT_CATEGORIES_NAME_ASC,
                            sortDesc: OrderByProductsCategoriesEnum.PRODUCT_CATEGORIES_NAME_DESC,
                            onSortChange: (order) => setFilter({ ...filter, order })
                        },
                    ]}>
                    {rows}
                </Table>
                <Pagination
                    page={productsCategories?.page}
                    pageSize={productsCategories?.pageSize}
                    count={productsCategories?.count}
                    recordCount={productsCategories?.records?.length}
                    onPageChange={page => setFilter({ ...filter, page })}
                />
            </>
        );
    }

    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={'Categorias de produtos '}
                    breadcrumbs={[
                        { name: 'Produtos', route: getDashboardRoute() },
                        { name: 'Categorias de produtos', route: getProductsCategoriesRoute() },
                    ]}
                    hideStore
                />
                <div className={'controls'}>
                    <div className={'gd'}>
                        <div className={`gd-col ${canAdd ? 'gd-col-9' : 'gd-col-12'}`}>
                            <FieldTextSearch
                                ref={inputSearchRef}
                                // I18N
                                label={`<em>Buscar por <strong>categoria</strong> [${ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY}]</em>`}
                                onChange={({ target }) =>
                                    setFilter({ ...filter, search: target.value })
                                }
                            />
                        </div>
                        {canAdd && (
                            <div className={'gd-col gd-col-3'}>
                                <Button
                                    buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                    icon={IconAdd}
                                    icon2x={IconAdd2x}
                                    route={getProductsCategoriesFormRoute()}
                                >
                                    {`Nova categoria [${ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY}]`}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
                <div className={'table-scroll'}>
                    {renderTable()}
                </div>
            </div>
        </>

    );
}

export function getProductsCategoriesRoute() {
    //I18N
    return '/produtos/categorias-de-produtos';
}
