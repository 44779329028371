import * as Sentry from '@sentry/react';
import axios from 'axios';
import {
    ENUM_SHORTCUT,
    OrderBySegmentationsEnum,
    PermissionsEnum,
} from 'erva-doce-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Button, { ButtonStyle } from '../../components/Button';
import FieldTextSearch from '../../components/FieldTextSearch';
import LoadError from '../../components/LoadError';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import { IconAdd, IconAdd2x } from '../../components/images';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as SegmentationsService from '../../services/SegmentationsService';
import { getSegmentationFormRoute } from './SegmentationForm';

export default function Segmentations() {
    const [segmentations, setSegmentations] = useState(null);
    const [segmentationsLoading, setSegmentationsLoading] = useState(null);
    const [filter, setFilter] = useState({
        order: OrderBySegmentationsEnum.NAME_ASC,
        search: null,
        page: 0
    });
    const inputSearchRef = useRef();
    const {
        addHotkey,
        removeHotkey,
        user,
    } = useContext(EnvironmentContext);

    const canAdd = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_PRODUCTS);
    const navigate = useNavigate();
    const handleRowClick = (route) => navigate(route);


    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        fetchSegmentations();
    }, [filter]);

    useEffect(() => {
        const shortcutSearchSecondary = addHotkey(ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY, () => {
            inputSearchRef.current?.focus();
        });

        const shortcutCreatePrimary = addHotkey(ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY, () => {
            navigate(getSegmentationFormRoute());
        });

        return () => {
            removeHotkey(shortcutSearchSecondary);
            removeHotkey(shortcutCreatePrimary);
        };
    }, []);

    async function fetchSegmentations() {
        if (segmentationsLoading?.cancel) {
            segmentationsLoading.cancel();
        }
        try {
            const cancelToken = axios.CancelToken.source().token;
            setSegmentationsLoading(cancelToken);
            const response = await SegmentationsService.getSegmentations(filter.page, filter.search, filter.order,
                cancelToken);
            setSegmentations(response);
            setSegmentationsLoading(null);
        } catch (e) {
            if (axios.isCancel(e)) {
                console.debug('Request cancelled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
                setSegmentationsLoading(e);
                setSegmentations(null);
            }
        }
    }

    function renderTable() {
        if (segmentationsLoading && segmentationsLoading instanceof Error) {
            return (
                <LoadError
                    // I18N
                    message={'Não foi possível carregar as segmentações'}
                    onTryAgain={fetchSegmentations}
                />
            );
        }

        let rows = [];
        // noinspection JSUnresolvedReference
        const records = segmentations?.records ?? [];
        for (const s of records) {
            rows.push(
                <tr key={s.uuid} onClick={() => handleRowClick(getSegmentationFormRoute(s.uuid))}>
                    <td>
                        {s.name}
                    </td>
                </tr>
            );
        }

        return (
            <>
                <Table
                    loading={segmentationsLoading}
                    currentSort={filter.order}
                    columns={[
                        {
                            // I18N
                            name: 'Nome da segmentação',
                            sortAsc: OrderBySegmentationsEnum.NAME_ASC,
                            sortDesc: OrderBySegmentationsEnum.NAME_DESC,
                            onSortChange: (order) => setFilter({ ...filter, order })
                        },
                    ]}>
                    {rows}
                </Table>
                <Pagination
                    page={segmentations?.page}
                    pageSize={segmentations?.pageSize}
                    count={segmentations?.count}
                    recordCount={segmentations?.records?.length}
                    onPageChange={page => setFilter({ ...filter, page })}
                />
            </>
        );
    }

    return (
        <div className={'crud-list'}>
            <ScreenHeader
                // I18N
                title={'Segmentações'}
                breadcrumbs={[
                    // I18N
                    { name: 'Produtos', route: getDashboardRoute() },
                    // I18N
                    { name: 'Segmentações', route: getSegmentationsRoute() },
                ]}
                hideStore
            />
            <div className={'controls'}>
                <div className={'gd'}>
                    <div className={`gd-col ${canAdd ? 'gd-col-9' : 'gd-col-12'}`}>
                        <FieldTextSearch
                            ref={inputSearchRef}
                            // I18N
                            label={`<em>Buscar por <strong>segmentação</strong> [${ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY}]</em>`}
                            onChange={({ target }) =>
                                setFilter({ ...filter, search: target.value })
                            }
                        />
                    </div>
                    {canAdd && (
                        <div className={'gd-col gd-col-3'}>
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                icon={IconAdd}
                                icon2x={IconAdd2x}
                                route={getSegmentationFormRoute()}
                            >
                                {`Nova segmentação [${ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY}]`}
                            </Button>
                        </div>
                    )}
                </div>
            </div>

            <div className={'table-scroll'}>
                {renderTable()}
            </div>
        </div>
    );
}

export function getSegmentationsRoute() {
    //I18N
    return '/produtos/segmentacoes';
}
