export enum CouponTypeEnum {
  FIXED = "fixed",
  PERCENT = "percent",
  FREE_SHIPPING = "freeShipping",
}

export function GetCouponType(type: CouponTypeEnum | string): string {
    switch (type) {
    case CouponTypeEnum.FIXED:
        return "fixed";
    case CouponTypeEnum.PERCENT:
        return "percent";
    case CouponTypeEnum.FREE_SHIPPING:
        return "freeShipping";

    default:
        throw new Error(`Not implemented for ${type}`);
    }
}
