import './ScreenHeader.scss';
import { NavLink } from 'react-router-dom';
import BackButton from '../BackButton';
import ProfileDropDown from '../ProfileDropDown';
import { MainMenuHamburguer } from '../images';
import { useContext } from 'react';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import NotificationCenter from './NotificationCenter';

export default function ScreenHeader({
    title,
    breadcrumbs,
    backRoute,
    color,
    hideStore,
}) {
    const { setMenuCollapsed } = useContext(EnvironmentContext);

    return (
        <div className={'screen-header'}>
            <div className={'header'}>
                <div className={'open-menu hide-desktop'}>
                    <a aria-label={'Abrir menu'} title={'Abrir menu'} onClick={() => setMenuCollapsed(false)}>
                        <img src={MainMenuHamburguer} alt={'Abrir menu'} />
                    </a>
                </div>
                <div>
                    {title && <h1 className={'heading1'} color={color}>{title}</h1>}
                    <div style={{ display: 'flex' }}>
                        <ul className={'breadcrumb'}>
                            {breadcrumbs && breadcrumbs.map(breadcrumb => (
                                <li key={breadcrumb.name}>
                                    {breadcrumb.route ? <NavLink to={breadcrumb.route}>{breadcrumb.name}</NavLink> : breadcrumb.name}
                                </li>
                            ))}
                        </ul>
                        {backRoute ? <BackButton route={backRoute}/> : <></>}
                    </div>
                </div>
            </div>
            <div className={'d-flex justify-content-end'}>
                <div className={'mr-16'}>
                    <NotificationCenter />
                </div>
                {
                    !hideStore && (
                        <ProfileDropDown className={'hide-mobile'} />
                    )
                }
            </div>
        </div>
    );
}
