export enum NcmEnum {
    NCM_68052000 = "NCM_68052000",
    NCM_48184090 = "NCM_48184090",
    NCM_33030020 = "NCM_33030020",
    NCM_28333000 = "NCM_28333000",
    NCM_28142000 = "NCM_28142000",
    NCM_82121020 = "NCM_82121020",
    NCM_85103000 = "NCM_85103000",
    NCM_44042000 = "NCM_44042000",
    NCM_95069100 = "NCM_95069100",
    NCM_67041900 = "NCM_67041900",
    NCM_96162000 = "NCM_96162000",
    NCM_65061000 = "NCM_65061000",
    NCM_25070010 = "NCM_25070010",
    NCM_65069100 = "NCM_65069100",
    NCM_65069900 = "NCM_65069900",
    NCM_17049010 = "NCM_17049010",
    NCM_18063210 = "NCM_18063210",
    NCM_33049910 = "NCM_33049910",
    NCM_33061000 = "NCM_33061000",
    NCM_33072010 = "NCM_33072010",
    NCM_19019020 = "NCM_19019020",
    NCM_25181000 = "NCM_25181000",
    NCM_96032100 = "NCM_96032100",
    NCM_96032900 = "NCM_96032900",
    NCM_82141000 = "NCM_82141000",
    NCM_70099200 = "NCM_70099200",
    NCM_84198920 = "NCM_84198920",
    NCM_32011000 = "NCM_32011000",
    NCM_56031320 = "NCM_56031320",
    NCM_56031190 = "NCM_56031190",
    NCM_56031290 = "NCM_56031290",
    NCM_33062000 = "NCM_33062000",
    NCM_76072000 = "NCM_76072000",
    NCM_39233000 = "NCM_39233000",
    NCM_15200010 = "NCM_15200010",
    NCM_96159000 = "NCM_96159000",
    NCM_29011000 = "NCM_29011000",
    NCM_28363000 = "NCM_28363000",
    NCM_82122010 = "NCM_82122010",
    NCM_33053000 = "NCM_33053000",
    NCM_48182000 = "NCM_48182000",
    NCM_62160000 = "NCM_62160000",
    NCM_85102000 = "NCM_85102000",
    NCM_84145910 = "NCM_84145910",
    NCM_82121010 = "NCM_82121010",
    NCM_40091290 = "NCM_40091290",
    NCM_40091100 = "NCM_40091100",
    NCM_62171000 = "NCM_62171000",
    NCM_85167990 = "NCM_85167990",
    NCM_85163200 = "NCM_85163200",
    NCM_63079010 = "NCM_63079010",
    NCM_63079090 = "NCM_63079090",
    NCM_56012190 = "NCM_56012190",
    NCM_95039010 = "NCM_95039010",
    NCM_35069900 = "NCM_35069900",
    NCM_49089000 = "NCM_49089000",
    NCM_33072090 = "NCM_33072090",
    NCM_56039290 = "NCM_56039290",
    NCM_94036000 = "NCM_94036000",
    NCM_70139900 = "NCM_70139900",
    NCM_44219000 = "NCM_44219000",
    NCM_39269090 = "NCM_39269090",
    NCM_15131900 = "NCM_15131900",
    NCM_33012990 = "NCM_33012990",
    NCM_21069090 = "NCM_21069090",
    NCM_33059000 = "NCM_33059000",
    NCM_33042090 = "NCM_33042090",
    NCM_85168090 = "NCM_85168090",
    NCM_39232990 = "NCM_39232990",
    NCM_68053090 = "NCM_68053090",
    NCM_29153939 = "NCM_29153939",
    NCM_39249000 = "NCM_39249000",
    NCM_39239000 = "NCM_39239000",
    NCM_30061090 = "NCM_30061090",
    NCM_39202019 = "NCM_39202019",
    NCM_18069000 = "NCM_18069000",
    NCM_40151900 = "NCM_40151900",
    NCM_84243090 = "NCM_84243090",
    NCM_96019000 = "NCM_96019000",
    NCM_30049099 = "NCM_30049099",
    NCM_30059090 = "NCM_30059090",
    NCM_96151900 = "NCM_96151900",
    NCM_12119090 = "NCM_12119090",
    NCM_33069000 = "NCM_33069000",
    NCM_33074900 = "NCM_33074900",
    NCM_18063120 = "NCM_18063120",
    NCM_33079000 = "NCM_33079000",
    NCM_34011190 = "NCM_34011190",
    NCM_35061090 = "NCM_35061090",
    NCM_33049990 = "NCM_33049990",
    NCM_34011900 = "NCM_34011900",
    NCM_48111000 = "NCM_48111000",
    NCM_90173020 = "NCM_90173020",
    NCM_27122000 = "NCM_27122000",
    NCM_85169000 = "NCM_85169000",
    NCM_56012110 = "NCM_56012110",
    NCM_96099000 = "NCM_96099000",
    NCM_96151100 = "NCM_96151100",
    NCM_28470000 = "NCM_28470000",
    NCM_67049000 = "NCM_67049000",
    NCM_96033000 = "NCM_96033000",
    NCM_33049100 = "NCM_33049100",
    NCM_33071000 = "NCM_33071000",
    NCM_33043000 = "NCM_33043000",
    NCM_20071000 = "NCM_20071000",
    NCM_33052000 = "NCM_33052000",
    NCM_35061010 = "NCM_35061010",
    NCM_33041000 = "NCM_33041000",
    NCM_34012010 = "NCM_34012010",
    NCM_85163100 = "NCM_85163100",
    NCM_62011300 = "NCM_62011300",
    NCM_33019010 = "NCM_33019010",
    NCM_33042010 = "NCM_33042010",
    NCM_62121000 = "NCM_62121000",
    NCM_82032090 = "NCM_82032090",
    NCM_82130000 = "NCM_82130000",
    NCM_82142000 = "NCM_82142000",
    NCM_48185000 = "NCM_48185000",
    NCM_39262000 = "NCM_39262000",
    NCM_33051000 = "NCM_33051000",
    NCM_85109020 = "NCM_85109020",
    NCM_85098090 = "NCM_85098090",
    NCM_34013000 = "NCM_34013000",
    NCM_56031340 = "NCM_56031340",
    NCM_65050090 = "NCM_65050090",
}

export function NcmValue(key: string) : string {
    const ncm = NcmEnum[key];
    switch (ncm) {
        case "NCM_68052000": return "68052000";
        case "NCM_48184090": return "48184090";
        case "NCM_33030020": return "33030020";
        case "NCM_28333000": return "28333000";
        case "NCM_28142000": return "28142000";
        case "NCM_82121020": return "82121020";
        case "NCM_85103000": return "85103000";
        case "NCM_44042000": return "44042000";
        case "NCM_95069100": return "95069100";
        case "NCM_67041900": return "67041900";
        case "NCM_96162000": return "96162000";
        case "NCM_65061000": return "65061000";
        case "NCM_25070010": return "25070010";
        case "NCM_65069100": return "65069100";
        case "NCM_65069900": return "65069900";
        case "NCM_17049010": return "17049010";
        case "NCM_18063210": return "18063210";
        case "NCM_33049910": return "33049910";
        case "NCM_33061000": return "33061000";
        case "NCM_33072010": return "33072010";
        case "NCM_19019020": return "19019020";
        case "NCM_25181000": return "25181000";
        case "NCM_96032100": return "96032100";
        case "NCM_96032900": return "96032900";
        case "NCM_82141000": return "82141000";
        case "NCM_70099200": return "70099200";
        case "NCM_84198920": return "84198920";
        case "NCM_32011000": return "32011000";
        case "NCM_56031320": return "56031320";
        case "NCM_56031190": return "56031190";
        case "NCM_56031290": return "56031290";
        case "NCM_33062000": return "33062000";
        case "NCM_76072000": return "76072000";
        case "NCM_39233000": return "39233000";
        case "NCM_15200010": return "15200010";
        case "NCM_96159000": return "96159000";
        case "NCM_29011000": return "29011000";
        case "NCM_28363000": return "28363000";
        case "NCM_82122010": return "82122010";
        case "NCM_33053000": return "33053000";
        case "NCM_48182000": return "48182000";
        case "NCM_62160000": return "62160000";
        case "NCM_85102000": return "85102000";
        case "NCM_84145910": return "84145910";
        case "NCM_82121010": return "82121010";
        case "NCM_40091290": return "40091290";
        case "NCM_40091100": return "40091100";
        case "NCM_62171000": return "62171000";
        case "NCM_85167990": return "85167990";
        case "NCM_85163200": return "85163200";
        case "NCM_63079010": return "63079010";
        case "NCM_63079090": return "63079090";
        case "NCM_56012190": return "56012190";
        case "NCM_95039010": return "95039010";
        case "NCM_35069900": return "35069900";
        case "NCM_49089000": return "49089000";
        case "NCM_33072090": return "33072090";
        case "NCM_56039290": return "56039290";
        case "NCM_94036000": return "94036000";
        case "NCM_70139900": return "70139900";
        case "NCM_44219000": return "44219000";
        case "NCM_39269090": return "39269090";
        case "NCM_15131900": return "15131900";
        case "NCM_33012990": return "33012990";
        case "NCM_21069090": return "21069090";
        case "NCM_33059000": return "33059000";
        case "NCM_33042090": return "33042090";
        case "NCM_85168090": return "85168090";
        case "NCM_39232990": return "39232990";
        case "NCM_68053090": return "68053090";
        case "NCM_29153939": return "29153939";
        case "NCM_39249000": return "39249000";
        case "NCM_39239000": return "39239000";
        case "NCM_30061090": return "30061090";
        case "NCM_39202019": return "39202019";
        case "NCM_18069000": return "18069000";
        case "NCM_40151900": return "40151900";
        case "NCM_84243090": return "84243090";
        case "NCM_96019000": return "96019000";
        case "NCM_30049099": return "30049099";
        case "NCM_30059090": return "30059090";
        case "NCM_96151900": return "96151900";
        case "NCM_12119090": return "12119090";
        case "NCM_33069000": return "33069000";
        case "NCM_33074900": return "33074900";
        case "NCM_18063120": return "18063120";
        case "NCM_33079000": return "33079000";
        case "NCM_34011190": return "34011190";
        case "NCM_35061090": return "35061090";
        case "NCM_33049990": return "33049990";
        case "NCM_34011900": return "34011900";
        case "NCM_48111000": return "48111000";
        case "NCM_90173020": return "90173020";
        case "NCM_27122000": return "27122000";
        case "NCM_85169000": return "85169000";
        case "NCM_56012110": return "56012110";
        case "NCM_96099000": return "96099000";
        case "NCM_96151100": return "96151100";
        case "NCM_28470000": return "28470000";
        case "NCM_67049000": return "67049000";
        case "NCM_96033000": return "96033000";
        case "NCM_33049100": return "33049100";
        case "NCM_33071000": return "33071000";
        case "NCM_33043000": return "33043000";
        case "NCM_20071000": return "20071000";
        case "NCM_33052000": return "33052000";
        case "NCM_35061010": return "35061010";
        case "NCM_33041000": return "33041000";
        case "NCM_34012010": return "34012010";
        case "NCM_85163100": return "85163100";
        case "NCM_62011300": return "62011300";
        case "NCM_33019010": return "33019010";
        case "NCM_33042010": return "33042010";
        case "NCM_62121000": return "62121000";
        case "NCM_82032090": return "82032090";
        case "NCM_82130000": return "82130000";
        case "NCM_82142000": return "82142000";
        case "NCM_48185000": return "48185000";
        case "NCM_39262000": return "39262000";
        case "NCM_33051000": return "33051000";
        case "NCM_85109020": return "85109020";
        case "NCM_85098090": return "85098090";
        case "NCM_34013000": return "34013000";
        case "NCM_56031340": return "56031340";
        case "NCM_65050090": return "65050090";
    }
}

export function NcmText(key: string) : string {
    const ncm = NcmEnum[key];
    switch (ncm) {
        case "NCM_68052000": return "NCM_68052000";
        case "NCM_48184090": return "NCM_48184090";
        case "NCM_33030020": return "NCM_33030020";
        case "NCM_28333000": return "NCM_28333000";
        case "NCM_28142000": return "NCM_28142000";
        case "NCM_82121020": return "NCM_82121020";
        case "NCM_85103000": return "NCM_85103000";
        case "NCM_44042000": return "NCM_44042000";
        case "NCM_95069100": return "NCM_95069100";
        case "NCM_67041900": return "NCM_67041900";
        case "NCM_96162000": return "NCM_96162000";
        case "NCM_65061000": return "NCM_65061000";
        case "NCM_25070010": return "NCM_25070010";
        case "NCM_65069100": return "NCM_65069100";
        case "NCM_65069900": return "NCM_65069900";
        case "NCM_17049010": return "NCM_17049010";
        case "NCM_18063210": return "NCM_18063210";
        case "NCM_33049910": return "NCM_33049910";
        case "NCM_33061000": return "NCM_33061000";
        case "NCM_33072010": return "NCM_33072010";
        case "NCM_19019020": return "NCM_19019020";
        case "NCM_25181000": return "NCM_25181000";
        case "NCM_96032100": return "NCM_96032100";
        case "NCM_96032900": return "NCM_96032900";
        case "NCM_82141000": return "NCM_82141000";
        case "NCM_70099200": return "NCM_70099200";
        case "NCM_84198920": return "NCM_84198920";
        case "NCM_32011000": return "NCM_32011000";
        case "NCM_56031320": return "NCM_56031320";
        case "NCM_56031190": return "NCM_56031190";
        case "NCM_56031290": return "NCM_56031290";
        case "NCM_33062000": return "NCM_33062000";
        case "NCM_76072000": return "NCM_76072000";
        case "NCM_39233000": return "NCM_39233000";
        case "NCM_15200010": return "NCM_15200010";
        case "NCM_96159000": return "NCM_96159000";
        case "NCM_29011000": return "NCM_29011000";
        case "NCM_28363000": return "NCM_28363000";
        case "NCM_82122010": return "NCM_82122010";
        case "NCM_33053000": return "NCM_33053000";
        case "NCM_48182000": return "NCM_48182000";
        case "NCM_62160000": return "NCM_62160000";
        case "NCM_85102000": return "NCM_85102000";
        case "NCM_84145910": return "NCM_84145910";
        case "NCM_82121010": return "NCM_82121010";
        case "NCM_40091290": return "NCM_40091290";
        case "NCM_40091100": return "NCM_40091100";
        case "NCM_62171000": return "NCM_62171000";
        case "NCM_85167990": return "NCM_85167990";
        case "NCM_85163200": return "NCM_85163200";
        case "NCM_63079010": return "NCM_63079010";
        case "NCM_63079090": return "NCM_63079090";
        case "NCM_56012190": return "NCM_56012190";
        case "NCM_95039010": return "NCM_95039010";
        case "NCM_35069900": return "NCM_35069900";
        case "NCM_49089000": return "NCM_49089000";
        case "NCM_33072090": return "NCM_33072090";
        case "NCM_56039290": return "NCM_56039290";
        case "NCM_94036000": return "NCM_94036000";
        case "NCM_70139900": return "NCM_70139900";
        case "NCM_44219000": return "NCM_44219000";
        case "NCM_39269090": return "NCM_39269090";
        case "NCM_15131900": return "NCM_15131900";
        case "NCM_33012990": return "NCM_33012990";
        case "NCM_21069090": return "NCM_21069090";
        case "NCM_33059000": return "NCM_33059000";
        case "NCM_33042090": return "NCM_33042090";
        case "NCM_85168090": return "NCM_85168090";
        case "NCM_39232990": return "NCM_39232990";
        case "NCM_68053090": return "NCM_68053090";
        case "NCM_29153939": return "NCM_29153939";
        case "NCM_39249000": return "NCM_39249000";
        case "NCM_39239000": return "NCM_39239000";
        case "NCM_30061090": return "NCM_30061090";
        case "NCM_39202019": return "NCM_39202019";
        case "NCM_18069000": return "NCM_18069000";
        case "NCM_40151900": return "NCM_40151900";
        case "NCM_84243090": return "NCM_84243090";
        case "NCM_96019000": return "NCM_96019000";
        case "NCM_30049099": return "NCM_30049099";
        case "NCM_30059090": return "NCM_30059090";
        case "NCM_96151900": return "NCM_96151900";
        case "NCM_12119090": return "NCM_12119090";
        case "NCM_33069000": return "NCM_33069000";
        case "NCM_33074900": return "NCM_33074900";
        case "NCM_18063120": return "NCM_18063120";
        case "NCM_33079000": return "NCM_33079000";
        case "NCM_34011190": return "NCM_34011190";
        case "NCM_35061090": return "NCM_35061090";
        case "NCM_33049990": return "NCM_33049990";
        case "NCM_34011900": return "NCM_34011900";
        case "NCM_48111000": return "NCM_48111000";
        case "NCM_90173020": return "NCM_90173020";
        case "NCM_27122000": return "NCM_27122000";
        case "NCM_85169000": return "NCM_85169000";
        case "NCM_56012110": return "NCM_56012110";
        case "NCM_96099000": return "NCM_96099000";
        case "NCM_96151100": return "NCM_96151100";
        case "NCM_28470000": return "NCM_28470000";
        case "NCM_67049000": return "NCM_67049000";
        case "NCM_96033000": return "NCM_96033000";
        case "NCM_33049100": return "NCM_33049100";
        case "NCM_33071000": return "NCM_33071000";
        case "NCM_33043000": return "NCM_33043000";
        case "NCM_20071000": return "NCM_20071000";
        case "NCM_33052000": return "NCM_33052000";
        case "NCM_35061010": return "NCM_35061010";
        case "NCM_33041000": return "NCM_33041000";
        case "NCM_34012010": return "NCM_34012010";
        case "NCM_85163100": return "NCM_85163100";
        case "NCM_62011300": return "NCM_62011300";
        case "NCM_33019010": return "NCM_33019010";
        case "NCM_33042010": return "NCM_33042010";
        case "NCM_62121000": return "NCM_62121000";
        case "NCM_82032090": return "NCM_82032090";
        case "NCM_82130000": return "NCM_82130000";
        case "NCM_82142000": return "NCM_82142000";
        case "NCM_48185000": return "NCM_48185000";
        case "NCM_39262000": return "NCM_39262000";
        case "NCM_33051000": return "NCM_33051000";
        case "NCM_85109020": return "NCM_85109020";
        case "NCM_85098090": return "NCM_85098090";
        case "NCM_34013000": return "NCM_34013000";
        case "NCM_56031340": return "NCM_56031340";
        case "NCM_65050090": return "NCM_65050090";
    }
}

export function NcmCodeAndText(key: string) : string {
    const ncm = NcmEnum[key];
    switch (ncm) {
        case "NCM_68052000": return "68052000 - ABRASIVOS NAT/ARTIF.EM PO/GRAO,APLIC.EM PAPEL/CARTAO KG";
        case "NCM_48184090": return "48184090 - ABSORVENTES E OUTS.ARTIGOS HIGIENICOS,DE PAPEL KG";
        case "NCM_33030020": return "33030020 - AGUA-DE-COLONIA KG";
        case "NCM_28333000": return "28333000 - ALUMES KG";
        case "NCM_28142000": return "28142000 - AMONIACO EM SOLUCAO AQUOSA (AMONIA) KG";
        case "NCM_82121020": return "82121020 - APARS.DE BARBEAR,NAO ELETRICOS UN";
        case "NCM_85103000": return "85103000 - APARS.DE DEPILAR,COM MOTOR ELETRICO UN";
        case "NCM_44042000": return "44042000 - ARCOS DE MADEIRA,ESTACAS FENDIDAS,ETC.DE NAO CONIFERAS KG";
        case "NCM_95069100": return "95069100 - ARTIGOS E EQUIPAMENTOS P/CULTURA FISICA,GINASTICA,ETC. KG";
        case "NCM_67041900": return "67041900 - BARBAS,SOBRANCELHAS,ETC.DE MATERIAS TEXTEIS SINTETICAS KG";
        case "NCM_96162000": return "96162000 - BORLAS/ESPONJAS P/POS/OUTS.COSMETICOS/PRODS.DE TOUCADOR KG";
        case "NCM_65061000": return "65061000 - CAPACETES E OUTS.ARTEFATOS,DE PROTECAO UN";
        case "NCM_25070010": return "25070010 - CAULIM KG";
        case "NCM_65069100": return "65069100 - CHAPEUS E OUTS.ARTEFS.DE BORRACHA OU DE PLASTICO KG";
        case "NCM_65069900": return "65069900 - CHAPEUS E OUTS.ARTEFS.DE OUTS.MATERIAS,EXC.DE MALHA KG";
        case "NCM_17049010": return "17049010 - CHOCOLATE BRANCO,SEM CACAU KG";
        case "NCM_18063210": return "18063210 - CHOCOLATE NAO RECHEADO,EM TABLETES,BARRAS E PAUS KG";
        case "NCM_33049910": return "33049910 - CREMES DE BELEZA,CREMES NUTRITIVOS E LOCOES TONICAS KG";
        case "NCM_33061000": return "33061000 - DENTIFRICIOS KG";
        case "NCM_33072010": return "33072010 - DESODORANTES CORPORAIS E ANTIPERSPIRANTES,LIQUIDOS KG";
        case "NCM_19019020": return "19019020 - DOCE DE LEITE KG";
        case "NCM_25181000": return "25181000 - DOLOMITA NAO CALCINADA NEM SINTERIZADA, CRUA KG";
        case "NCM_96032100": return "96032100 - ESCOVAS DE DENTES,INCL.AS ESCOVAS P/DENTADURAS UN";
        case "NCM_96032900": return "96032900 - ESCOVAS E PINCEIS DE BARBA,ESCOVAS P/CABELOS,CILIOS,ETC UN";
        case "NCM_82141000": return "82141000 - ESPATULAS,ABRE-CARTAS,ETC.E SUAS LAMINAS,DE MET.COMUNS KG";
        case "NCM_70099200": return "70099200 - ESPELHOS DE VIDRO,EMOLDURADOS KG";
        case "NCM_84198920": return "84198920 - ESTUFAS UN";
        case "NCM_32011000": return "32011000 - EXTRATO TANANTE,DE QUEBRACHO KG";
        case "NCM_56031320": return "56031320 - FALSOS TECIDOS DE FILAMENTOS DE ARAMIDA,70";
        case "NCM_56031190": return "56031190 - FALSOS TECIDOS DE OUTS.FILAMENTOS SINT/ARTIF.P<=25G/M2 KG";
        case "NCM_56031290": return "56031290 - FALSOS TECIDOS DE OUTS.FILAM.SINT/ARTIF.25";
        case "NCM_33062000": return "33062000 - FIOS UTILIZ.P/LIMPAR ESPACOS INTERDENTAIS (FIO DENTAL) KG";
        case "NCM_76072000": return "76072000 - FOLHAS/TIRAS,DE ALUMINIO,C/SUPORTE,ESP<=0.2MM KG";
        case "NCM_39233000": return "39233000 - GARRAFOES,GARRAFAS,FRASCOS,ARTIGOS SEMELHS.DE PLASTICOS KG";
        case "NCM_15200010": return "15200010 - GLICERINA EM BRUTO KG";
        case "NCM_96159000": return "96159000 - GRAMPOS P/CABELO,PINCAS E OUTS.ARTIGOS P/PENTEADOS KG";
        case "NCM_29011000": return "29011000 - HIDROCARBONETOS ACICLICOS SATURADOS KG";
        case "NCM_28363000": return "28363000 - HIDROGENOCARBONATO (BICARBONATO) DE SODIO KG";
        case "NCM_82122010": return "82122010 - LAMINAS DE BARBEAR,DE SEGURANCA,DE METAIS COMUNS UN";
        case "NCM_33053000": return "33053000 - LAQUES PARA OS CABELOS KG";
        case "NCM_48182000": return "48182000 - LENCOS E TOALHAS DE MAO,DE PAPEL KG";
        case "NCM_62160000": return "62160000 - LUVAS,MITENES E SEMELHANTES PR";
        case "NCM_85102000": return "85102000 - MAQS.DE CORTAR O CABELO/TOSQUIAR,C/MOTOR ELETR. UN";
        case "NCM_84145910": return "84145910 - MICROVENTILADORES C/AREA DE CARCACA<90CM2 UN";
        case "NCM_82121010": return "82121010 - NAVALHAS DE BARBEAR,DE METAIS COMUNS UN";
        case "NCM_40091290": return "40091290 - NOVO PRODUTO UN";
        case "NCM_40091100": return "40091100 - NOVO PRODUTO UN";
        case "NCM_62171000": return "62171000 - OUTROS ACESSORIOS CONFECCIONADOS,DE VESTUARIO KG";
        case "NCM_85167990": return "85167990 - OUTROS APARS.ELETROTERMICOS,USO DOMESTICO UN";
        case "NCM_85163200": return "85163200 - OUTROS APARS.P/ARRANJOS DO CABELO,ELETROTERMICOS UN";
        case "NCM_63079010": return "63079010 - OUTROS ARTEFATOS CONFECCIONADOS,DE FALSO TECIDO KG";
        case "NCM_63079090": return "63079090 - OUTROS ARTEFATOS TEXTEIS CONFECCIONADOS KG";
        case "NCM_56012190": return "56012190 - OUTROS ARTIGOS DE PASTAS (OUATES) DE ALGODAO KG";
        case "NCM_95039010": return "95039010 - OUTROS BRINQUEDOS DE FRICCAO,DE CORDA OU DE MOLA UN";
        case "NCM_35069900": return "35069900 - OUTROS COLAS E ADESIVOS PREPARADOS KG";
        case "NCM_49089000": return "49089000 - OUTROS DECALCOMANIAS DE QQ.ESPECIE KG";
        case "NCM_33072090": return "33072090 - OUTROS DESODORANTES CORPORAIS E ANTIPERSPIRANTES KG";
        case "NCM_56039290": return "56039290 - OUTROS FALSOS TECIDOS,25G/M2";
        case "NCM_94036000": return "94036000 - OUTROS MOVEIS DE MADEIRA UN";
        case "NCM_70139900": return "70139900 - OUTROS OBJETOS DE VIDRO,P/TOUCADOR,ESCRITORIO,ETC. KG";
        case "NCM_44219000": return "44219000 - OUTROS OBRAS DE MADEIRA KG";
        case "NCM_39269090": return "39269090 - OUTROS OBRAS DE PLASTICOS KG";
        case "NCM_15131900": return "15131900 - OUTROS OLEOS DE COCO (OLEOS DE COPRA) KG";
        case "NCM_33012990": return "33012990 - OUTROS OLEOS ESSENCIAIS KG";
        case "NCM_21069090": return "21069090 - OUTROS PREPARACOES ALIMENTICIAS KG";
        case "NCM_33059000": return "33059000 - OUTROS PREPARACOES CAPILARES KG";
        case "NCM_33042090": return "33042090 - OUTROS PRODUTOS DE MAQUILAGEM PARA OS OLHOS KG";
        case "NCM_85168090": return "85168090 - OUTROS RESISTENCIAS DE AQUECIMENTO UN";
        case "NCM_39232990": return "39232990 - OUTROS SACOS,BOLSAS E CARTUCHOS,DE OUTS.PLASTICOS KG";
        case "NCM_68053090": return "68053090 - OUTS.ABRASIVOS NAT/ARTIF.EM PO/GRAO APLIC.OUTS.MATERIAS KG";
        case "NCM_29153939": return "29153939 - OUTS.ACETATOS MONOALCOOIS ACICL.SATUR.ATOMOS CARBONO<=8 KG";
        case "NCM_39249000": return "39249000 - OUTS.ARTIGOS DE HIGIENE OU DE TOUCADOR,DE PLASTICO KG";
        case "NCM_39239000": return "39239000 - OUTS.ARTIGOS DE TRANSPORTE OU DE EMBALAGEM,DE PLASTICOS KG";
        case "NCM_30061090": return "30061090 - OUTS.CATEGUTES ESTERILIZADOS,ETC.P/SUTURAS CIRURGICAS KG";
        case "NCM_39202019": return "39202019 - OUTS.CHAPAS,ETC.POLIM.PROPILENO,BIAX.ORIENT.S/SUPORTE KG";
        case "NCM_18069000": return "18069000 - OUTS.CHOCOLATES E PREPARACOES ALIMENTICIAS CONT.CACAU KG";
        case "NCM_40151900": return "40151900 - OUTS.LUVAS DE BORRACHA VULCANIZADA,NAO ENDURECIDA PR";
        case "NCM_84243090": return "84243090 - OUTS.MAQS.E APARS.DE JATO DE AREIA/JATO DE VAPOR,ETC. UN";
        case "NCM_96019000": return "96019000 - OUTS.MATERIAS ANIMAIS P/ENTALHAR,TRABALHADOS E OBRAS KG";
        case "NCM_30049099": return "30049099 - OUTS.MEDICAM.CONT.PRODS.P/FINS TERAPEUTICOS,ETC.DOSES KG";
        case "NCM_30059090": return "30059090 - OUTS.PASTAS,GAZES,SEMELHS.P/USO MEDICINAL,CIRURGICO,ETC KG";
        case "NCM_96151900": return "96151900 - OUTS.PENTES E TRAVESSAS P/CABELO,DE OUTS.MATERIAS KG";
        case "NCM_12119090": return "12119090 - OUTS.PLANTAS E PARTES,P/PERFUMARIA,MEDICINA E SEMELHS. KG";
        case "NCM_33069000": return "33069000 - OUTS.PREPARACOES PARA HIGIENE BUCAL OU DENTARIA,ETC. KG";
        case "NCM_33074900": return "33074900 - OUTS.PREPARACOES PARA PERFUMAR OU DESODORIZAR AMBIENTES KG";
        case "NCM_18063120": return "18063120 - OUTS.PREPARS.ALIMENTS.C/CACAU,RECHEADAS,EM TABLETES,ETC KG";
        case "NCM_33079000": return "33079000 - OUTS.PRODS.DE PERFUMARIA OU TOUCADOR,PREPARADOS,ETC. KG";
        case "NCM_34011190": return "34011190 - OUTS.PRODS/PREPARS.DE TOUCADOR,EM BARRAS,PEDACOS,ETC. KG";
        case "NCM_35061090": return "35061090 - OUTS.PRODS.UTILIZADOS COMO COLAS OU ADESIVOS,PESO<=1KG KG";
        case "NCM_33049990": return "33049990 - OUTS.PRODUTOS DE BELEZA OU DE MAQUILAGEM PREPARADOS,ETC KG";
        case "NCM_34011900": return "34011900 - OUTS.SABOES/PRODUTOS/PREPARACOES,EM BARRAS,PEDACOS,ETC. KG";
        case "NCM_48111000": return "48111000 - PAPEL E CARTAO ALCATROADOS,BETUMINADOS,ETC.EM ROLOS,FLS KG";
        case "NCM_90173020": return "90173020 - PAQUIMETROS (INSTRUM.DE MEDIDA MANUAL DE DISTANCIAS) UN";
        case "NCM_27122000": return "27122000 - PARAFINA CONTENDO PESO<0.75% DE OLEO KG";
        case "NCM_85169000": return "85169000 - PARTES DE AQUECEDORES ELETR/APARS.ELETR.P/AQUECIM.ETC. KG";
        case "NCM_56012110": return "56012110 - PASTAS (OUATES) DE ALGODAO KG";
        case "NCM_96099000": return "96099000 - PASTEIS,CARVOES,GIZES P/ESCREVER/DESENHAR E DE ALFAIATE KG";
        case "NCM_96151100": return "96151100 - PENTES E TRAVESSAS P/CABELO,DE BORRACHA ENDUR/PLASTICO KG";
        case "NCM_28470000": return "28470000 - PEROXIDO DE HIDROGENIO (AGUA OXIGENADA) KG";
        case "NCM_67049000": return "67049000 - PERUCAS,BARBAS,SOBRANCELHAS,ETC.DE OUTS.MATERS.TEXTEIS KG";
        case "NCM_96033000": return "96033000 - PINCEIS E ESCOVAS P/ARTISTAS,P/PRODS.COSMETICOS,ETC. UN";
        case "NCM_33049100": return "33049100 - POS,INCLUIDOS OS COMPACTOS,PARA MAQUILAGEM KG";
        case "NCM_33071000": return "33071000 - PREPARACOES PARA BARBEAR (ANTES,DURANTE OU APOS) KG";
        case "NCM_33043000": return "33043000 - PREPARACOES PARA MANICUROS E PEDICUROS KG";
        case "NCM_20071000": return "20071000 - PREPARS.HOMOGEN.DE FRUTAS,POR COZIMENTO,P/ALIM.CRIANCAS KG";
        case "NCM_33052000": return "33052000 - PREPARS.P/ONDULACAO/ALISAMENTO/PERMANENTES,DOS CABELOS KG";
        case "NCM_35061010": return "35061010 - PRODS.UTIL.COMO COLAS,ADESIVOS,DE CIANOACRILATOS,P<=1KG KG";
        case "NCM_33041000": return "33041000 - PRODUTOS DE MAQUILAGEM PARA OS LABIOS KG";
        case "NCM_34012010": return "34012010 - SABOES DE TOUCADOR,SOB OUTS.FORMAS KG";
        case "NCM_85163100": return "85163100 - SECADORES DE CABELO,ELETROTERMICOS UN";
        case "NCM_62011300": return "62011300 - SOBRETUDOS,IMPERMEAV.ETC.DE FIBRA SINT/ARTIF.MASCULINO UN";
        case "NCM_33019010": return "33019010 - SOLUCOES CONCENTR.DE OLEOS ESSENCIAIS DO TRATAM.FLORES KG";
        case "NCM_33042010": return "33042010 - SOMBRA,DELINEADOR,LAPIS PARA SOBRANCELHAS E RIMEL KG";
        case "NCM_62121000": return "62121000 - SUTIAS E BUSTIERS (SOUTIENS DE COS ALTO) UN";
        case "NCM_82032090": return "82032090 - TENAZES,PINCAS,FERRAM.MANUAIS SEMELH.DE METAIS COMUNS KG";
        case "NCM_82130000": return "82130000 - TESOURAS E SUAS LAMINAS,DE METAIS COMUNS KG";
        case "NCM_82142000": return "82142000 - UTENSIL/SORTIDO UTENSIL.MANICURO/PEDICURO,DE MET.COMUNS KG";
        case "NCM_48185000": return "48185000 - VESTUARIO E SEUS ACESSORIOS,DE PAPEL KG";
        case "NCM_39262000": return "39262000 - VESTUARIO E SEUS ACESSORIOS,DE PLASTICOS,INCL.LUVAS KG";
        case "NCM_33051000": return "33051000 - XAMPUS PARA OS CABELOS KG";
        case "NCM_85109020": return "85109020 - PENTES E CONTRAPENTES PARA MAQUINAS DE TOSQUIAR";
        case "NCM_85098090": return "85098090 - APARELHOS ELETROMECANICOS COM MOTOR INCORPORADO";
        case "NCM_34013000": return "34013000 - PRODUTOS E PREPARACOES ORGANICOS TENSOATIVOS";
        case "NCM_56031340": return "56031340 - PRODS POLIPROPILENO";
        case "NCM_65050090": return "65050090 - HAPÉUS E OUTROS ARTIGOS DE USO SEMELHANTE, DE MALHA OU CONFECCIONADOS COM RENDAS,FELTROOUOUTROSPRODU";
    }
}



