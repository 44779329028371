import './MainScreen.scss';
import MainMenu from './MainMenu';
import { useContext, useEffect } from 'react';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { useLocation } from 'react-router';
import NewMenu from './NewMenu';

export default function MainScreen({
    children,
    className,
    title,
}) {
    const { ready, setWindowTitle } = useContext(EnvironmentContext);
    const location = useLocation();

    useEffect(() => {
        setWindowTitle(title || '');
    }, [location.pathname]);

    return (
        <div className={`main-screen ${className || ''}`}>
            {/* <MainMenu/> */}
            <NewMenu/>
            <div className={`
                screen
                ${ready ? 'ready' : ''}
            `}>
                {children}
            </div>
        </div>
    );
}
