import { useContext, useEffect, useRef, useState } from 'react';
import FieldTextSearch from '../../../components/FieldTextSearch';
import ScreenHeader from '../../../components/logged/ScreenHeader';
import { getDashboardRoute } from '../../../dashboard/Dashboard';
import {
    getAllEmailsByNewsletter,
    updateStatusEmailOfNewsletter,
} from '../../../services/NewsletterService';
import Table from '../../../components/Table';
import Pagination from '../../../components/Pagination';
import FieldCheckbox from '../../../components/FieldCheckbox';
import { EnvironmentContext } from '../../../contexts/EnviromentContext';
import { ENUM_SHORTCUT } from 'erva-doce-common';

const FILTERS = {
    search: null,
    page: 0,
};

export default function Newsletter() {
    const title = 'Newsletters';
    const [filter, setFilter] = useState(FILTERS);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(false);
    const inputSearchRef = useRef();
    const {
        addHotkey,
        removeHotkey,
    } = useContext(EnvironmentContext);

    const getEmailsByNewsletter = async () => {
        try {
            setIsLoading(true);

            const response = await getAllEmailsByNewsletter({ filter: filter });

            setData(response);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    const updateStatusEmail = async ({ id }) => {
        try {
            await updateStatusEmailOfNewsletter({ id: id });
            getEmailsByNewsletter();
        } catch (error) {
            console.error('Erro ao atualizar email', error);
        }
    };

    useEffect(() => {
        getEmailsByNewsletter();
    }, [filter]);

    useEffect(() => {
        const shortcutSearchSecondary = addHotkey(ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY, () => {
            inputSearchRef.current?.focus();
        });

        return () => {
            removeHotkey(shortcutSearchSecondary);
        };
    }, []);

    return (
        <>
            <div>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Loja virtual', route: getDashboardRoute() },
                        { name: 'Marketing', route: getDashboardRoute() },
                        { name: title, route: getNewsletterRoute() },
                    ]}
                    hideStore
                />
                <div className={'controls'}>
                    <div className={'gd'}>
                        <div className={'gd-col gd-col-6'}>
                            <FieldTextSearch
                                ref={inputSearchRef}
                                label={
                                    `<em>Buscar por <strong>e-mail</strong> [${ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY}]</em>`
                                }
                                onChange={({ target }) =>
                                    setFilter({
                                        ...filter,
                                        search: target.value,
                                    })
                                }
                            />
                        </div>
                        <div className={'gd-col gd-col-3'}></div>

                    </div>
                </div>
                <div className={'mt-24'}>
                    <Table
                        loading={isLoading}
                        currentSort={filter.order}
                        columns={[
                            {
                                name: 'Email',
                                align: 'center',
                            },
                            {
                                name: 'Status',
                                align: 'center',
                            },
                            {
                                name: 'Assinado em',
                                align: 'center',
                            },
                            {
                                name: 'Cancelado em',
                                align: 'center',
                            },
                            {
                                name: 'E-mail ativo',
                                align: 'center',
                            },
                        ]}
                    >
                        {data?.records?.map((newsletter, index) => (
                            <tr key={index}>
                                <td className={'text-center'}>
                                    {newsletter.email}
                                </td>
                                <td className={'text-center'}>
                                    {newsletter.status}
                                </td>
                                <td className={'text-center'}>
                                    {newsletter.createdAt}
                                </td>
                                <td className={'text-center'}>
                                    {newsletter.isActive ? '-' : newsletter.canceledAt}
                                </td>
                                <td className={'text-center'}>
                                    <FieldCheckbox
                                        className={'mb-0'}
                                        type={'checkbox'}
                                        inputs={[
                                            {
                                                value: !!newsletter.isActive,
                                                onChange: () => { },
                                                onClick: () =>
                                                    updateStatusEmail({
                                                        id: newsletter.id,
                                                    }),
                                                checked: !!newsletter.isActive,
                                            },
                                        ]}
                                    />
                                </td>
                            </tr>
                        ))}
                    </Table>
                    <Pagination
                        page={data?.page}
                        pageSize={data?.pageSize}
                        count={data?.count}
                        recordCount={data?.records?.length}
                        onPageChange={(page) => setFilter({ ...filter, page })}
                    />
                </div>
            </div>
        </>
    );
}

export function getNewsletterRoute() {
    return '/loja-virtual/marketing/newsletters';
}
