import { useEffect, useState } from 'react';
import './SwichToggle.scss';
import classNames from 'classnames';

export default function SwitchToggle({
    label,
    onChange,
    className,
    disabled = false,
    defaultChecked = false,
}) {
    const [isChecked, setIsChecked] = useState(defaultChecked);

    const onClickHender = () => {
        setIsChecked((prev) => !prev);
        onChange && onChange(!isChecked);
    };

    useEffect(() => {
        setIsChecked(defaultChecked);
    }, [defaultChecked]);

    return (
        <label className={classNames('switch-container', className)}>
            <label className={'switch'}>
                <input
                    type={'checkbox'}
                    checked={isChecked}
                    onChange={onClickHender}
                    disabled={disabled}
                />
                <span className={'slider'}></span>
            </label>
            <span className={'switch-label'}>{label}</span>
        </label>
    );
}
