import { useRef } from 'react';
import './SupplierRepresentativeHeader.scss';

export default function SupplierRepresentativeHeader({
    columns,
    children,
}) {
    const tbodyRef = useRef();
    const empty = !children || !children.length;

    return (
        <div className={'purchase-table'}>

            <table className={'table'}>
                <thead>
                    <tr>
                        <th>{}</th>
                        <th>{}</th>
                        <th>{}</th>
                        <th>{'Valor mínimo'}</th>
                    </tr>
                </thead>
                <tbody ref={tbodyRef}>
                    {!empty && (
                        <tr>
                            <td colSpan={columns?.length} className={'status-container'}>
                                {'Não há dados para exibir'}
                            </td>
                        </tr>
                    )}
                    {empty && (
                        children
                    )}

                </tbody>
            </table>
        </div>
    );
}
