/* eslint-disable indent */
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { ENUM_SHORTCUT, ExchangeStatusEnum, ExchangeStatusText, OrderByExchangesEnum } from 'erva-doce-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Button, { ButtonColor, ButtonStyle } from '../../components/Button';
import LoadError from '../../components/LoadError';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import { IconAdd, IconAdd2x, IconExchangeStatusAwaitingRescue, IconExchangeStatusInEdit, IconExchangeStatusRescued, IconFilter, IconFilter2x } from '../../components/images';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as ExchangesService from '../../services/ExchangesService';
import ExchangesFilterModal from './ExchangesFilterModal';
import { getExchangesFormRoute } from './ExchangesForm';
import FieldCpfSearch from '../../components/FieldCpfSearch';
import { unmaskCpf } from '../../components/FieldCpf';
import { getSalesFormRoute } from '../sales/SalesForm';
import { Link } from 'react-router-dom';

export default function Exchanges() {
    const [exchanges, setExchanges] = useState(null);
    const [exchangesLoading, setExchangesLoading] = useState(null);
    const [filter, setFilter] = useState({
        order: OrderByExchangesEnum.STATUS_DESC,
        search: null,
        page: 0
    });
    const inputSearchRef = useRef();
    const {
        addHotkey,
        removeHotkey,
        selectedStore,
    } = useContext(EnvironmentContext);
    const [showFilter, setShowFilter] = useState(false);

    const navigate = useNavigate();
    const handleRowClick = (route) => navigate(route);


    useEffect(() => {
        fetchExchanges();
    }, [filter, selectedStore]);

    useEffect(() => {
        const shortcutSearchSecondary = addHotkey(ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY, () => {
            if (inputSearchRef.current) {
                // inputSearchRef.current.focus();
            }
        });

        return () => {
            removeHotkey(shortcutSearchSecondary);
        };
    }, []);

    async function fetchExchanges() {
        if (exchangesLoading?.cancel) {
            exchangesLoading.cancel();
        }
        try {
            const cancelToken = axios.CancelToken.source().token;
            setExchangesLoading(cancelToken);
            const response = await ExchangesService.getExchanges(filter.page, unmaskCpf(filter.search), filter.order,
                cancelToken);
            setExchanges(response);
            setExchangesLoading(null);
        } catch (e) {
            if (axios.isCancel(e)) {
                console.debug('Request cancelled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
                setExchangesLoading(e);
                setExchanges(null);
            }
        }
    }

    function verifyStatus(status) {
        switch (status) {
            case ExchangeStatusEnum.IN_EDIT:
                return IconExchangeStatusInEdit;
            case ExchangeStatusEnum.AWAITING_RESCUE:
                return IconExchangeStatusAwaitingRescue;
            case ExchangeStatusEnum.RESCUED:
                return IconExchangeStatusRescued;
        }
    }

    function renderTable() {
        if (exchangesLoading && exchangesLoading instanceof Error) {
            return (
                <LoadError
                    // I18N
                    message={'Não foi possível carregar as devoluções'}
                    onTryAgain={fetchExchanges}
                />
            );
        }

        let rows = [];
        // noinspection JSUnresolvedReference
        const records = exchanges?.records ?? [];
        for (const e of records) {
            rows.push(
                <tr key={e.uuid} onClick={() => handleRowClick(getExchangesFormRoute(e.uuid))}>
                    <td>
                        {e.number}
                    </td>
                    <td>
                        {new Date(e.createdAt).toLocaleDateString('pt-br')}
                    </td>
                    <td>
                        {e.customerName}
                    </td>
                    <td>
                        {ExchangeStatusText(e.status)}
                    </td>
                    <td className={'text-center'}>
                        {e.storeName}
                    </td>
                    <td className={'text-center'}>
                        {e.total?.toLocaleString(undefined, { style: 'currency', currency: 'BRL', })}
                    </td>
                    <td className={'text-center'}>
                        {
                            e.saleOriginId ? (
                                <Link onClick={(e) => e.stopPropagation()} target={'_blank'} to={getSalesFormRoute(e.saleOriginUUID)}>{e.saleOriginId}</Link>
                            ) : '-'
                        }
                    </td>
                    <td className={'text-center'}>
                        {
                            e.saleId ? (
                                <Link onClick={(e) => e.stopPropagation()} target={'_blank'} to={getSalesFormRoute(e.saleUUID)}>{e.saleId}</Link>
                            ) : '-'
                        }
                    </td>
                </tr>
            );
        }

        return (
            <>
                <Table
                    loading={exchangesLoading}
                    currentSort={filter.order}
                    columns={[
                        {
                            // I18N
                            name: 'Nº Devolução',
                        },
                        {
                            // I18N
                            name: 'Data',
                            sortAsc: OrderByExchangesEnum.DATE_ASC,
                            sortDesc: OrderByExchangesEnum.DATE_DESC,
                            onSortChange: (order) => setFilter({ ...filter, order }),
                        },
                        {
                            // I18N
                            name: 'Nome do cliente',
                        },
                        {
                            // I18N
                            name: 'Status',
                            sortAsc: OrderByExchangesEnum.STATUS_ASC,
                            sortDesc: OrderByExchangesEnum.STATUS_DESC,
                            onSortChange: (order) => setFilter({ ...filter, order }),
                        },
                        {
                            // I18N
                            name: 'Loja',
                            align: 'center',

                        },
                        {
                            // I18N
                            name: 'Valor',
                            align: 'center',
                        },
                        {
                            name: 'Compra de origem',
                            align: 'center',
                        },
                        {
                            name: 'Compra resgate',
                            align: 'center',
                        }
                    ]}>
                    {rows}
                </Table>
                <Pagination
                    page={exchanges?.page}
                    pageSize={exchanges?.pageSize}
                    count={exchanges?.count}
                    recordCount={exchanges?.records?.length}
                    onPageChange={page => setFilter({ ...filter, page })}
                />
            </>
        );
    }

    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    // I18N
                    title={'Devoluções'}
                    breadcrumbs={[
                        // I18N
                        { name: 'Vendas', route: getDashboardRoute() },
                        // I18N
                        { name: 'Devoluções', route: getExchangesRoute() },
                    ]}
                />
                <div className={'controls'}>
                    <div className={'gd'}>
                        <div className={'gd-col gd-col-6'}>
                            <FieldCpfSearch
                                ref={inputSearchRef}
                                placeholder={'Buscar por CPF do cliente'}
                                onChange={({ target }) =>
                                    setFilter({ ...filter, search: target.value })
                                }
                            />
                        </div>
                        <div className={'gd-col gd-col-3'}>
                        </div>
                        <div className={'gd-col gd-col-3'}>
                        </div>
                    </div>
                </div>

                <div className={'table-scroll'}>
                    {renderTable()}
                </div>
            </div>
            <ExchangesFilterModal
                show={showFilter}
                onCancel={() => setShowFilter(false)}
                filter={filter}
                onConfirm={(complementaryFilter) => {
                    setShowFilter(false);
                    // noinspection JSCheckFunctionSignatures
                    setFilter({
                        ...filter,
                        ...complementaryFilter,
                    });
                }}
            />
        </>
    );
}

export function getExchangesRoute() {
    //I18N
    return '/vendas/devolucoes';
}
