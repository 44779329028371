export enum ENUM_SHORTCUT {
  SHORTCUT_SEARCH_PRIMARY = "F1", // Busca no sistema
  SHORTCUT_SEARCH_SECONDARY = "F2", // Busca no sistema
  SHORTCUT_DELETE = "F3", // Excluir no sistema
  SHORTCUT_CANCEL = "F4", // Cancelar/fechar no sistema
  SHORTCUT_CONFIRM = "F6", // Confirmar/salvar no sistema
  SHORTCUT_REFRESH = "F7", // Atualizar algo no sistema
  SHORTCUT_RETRIEVE = "F8", // Resgatar alguma informação
  SHORTCUT_CREATE_PRIMARY = "F9", // Criar/importar algo novo
  SHORTCUT_CREATE_SECONDARY = "F10", // Criar/importar algo novo
  SHORTCUT_FILTER = "F11", // Filtrar no sistema
}

export function Shortcut(enumShortcut: ENUM_SHORTCUT | string) {
    switch (enumShortcut) {
    case ENUM_SHORTCUT.SHORTCUT_SEARCH_PRIMARY:
        return "F1";
    case ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY:
        return "F2";
    case ENUM_SHORTCUT.SHORTCUT_DELETE:
        return "F3";
    case ENUM_SHORTCUT.SHORTCUT_CANCEL:
        return "F4";
    case ENUM_SHORTCUT.SHORTCUT_CONFIRM:
        return "F6";
    case ENUM_SHORTCUT.SHORTCUT_REFRESH:
        return "F7";
    case ENUM_SHORTCUT.SHORTCUT_RETRIEVE:
        return "F8";
    case ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY:
        return "F9";
    case ENUM_SHORTCUT.SHORTCUT_CREATE_SECONDARY:
        return "F10";
    case ENUM_SHORTCUT.SHORTCUT_FILTER  :
        return "F11";
    default:
        throw new Error(`Not implemented for ${enumShortcut}`);
    }
}
