import * as Sentry from '@sentry/react';
import axios from 'axios';
import { ENUM_SHORTCUT, ExchangeStatusEnum, PaymentsEnum, PaymentsTypeValue, PermissionsEnum, unmaskCpf, ValidationErrorCfeText, ValidationErrorExchangesText, TypeNFSaleEnum } from 'erva-doce-common';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Amount from '../../components/Amount';
import Button, { ButtonColor, ButtonFontColor, ButtonStyle } from '../../components/Button';
import ButtonRemove from '../../components/ButtonRemove';
import { extractCurrencyNumber } from '../../components/FieldCurrency';
import FieldInteger from '../../components/FieldInteger';
import FieldTextSearch from '../../components/FieldTextSearch';
import RouteChangePrompt from '../../components/RouteChangePrompt';
import SearchExchangeModal from '../../components/SearchExchangeModal';
import SearchProductModal from '../../components/SearchProductModal';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import * as ExchangesService from '../../services/ExchangesService';
import * as ProductsService from '../../services/ProductsService';
import * as SalesService from '../../services/SalesService';
import * as FocusApiService from '../../services/FocusApiService';
import { formatValue } from '../../util/formatValue';
import { getSalesRoute } from './Sales';
import './SalesForm.scss';
import SalesModal from './SalesModal';
import SalesPaymentModal from './SalesPaymentModal';
import currency from 'currency.js';
import CustomersFormModal from '../customers/CustomersFormModal';
import * as CustomerService from '../../services/CustomerService';
import CashierOpeningModal from './CashierOpeningModal';
import * as CashiersService from '../../services/CashiersService';
import * as CouponService from '../../services/CouponService';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import { getExchangesFormRoute } from '../exchanges/ExchangesForm';
import DevolutionSaleModal from './DevolutionSaleModal';
import { formatCpfMask } from 'erva-doce-common';

import ConfirmPrintNF from '../../components/ConfirmPrintNF';
import { auto } from '@popperjs/core';
import { set } from 'lodash';
import { Link } from 'react-router-dom';
import FieldText from '../../components/FieldText';

export default function SalesForm() {
    const {
        backendConnectionError,
        setInfoModal,
        addHotkey,
        removeHotkey,
        user,
        setConfirmModal,
        setLoading,
        selectedStore,
    } = useContext(EnvironmentContext);

    const emptyFormData = {
        payments: [],

    };

    const emptyPayments = {
        quantityInstallments: 1,
        amountInstallments: 'R$ 0,00',
        total: 'R$ 0,00',
        paymentType: null,
        machine: null,
        newRow: true,
    };
    const emptyFormError = emptyFormData;
    const [formData, setFormData] = useState(emptyFormData);
    const [, setFormError] = useState(emptyFormError);
    const [, setValidateOnChange] = useState(false);
    const { uuid } = useParams();
    const [saleName, setSaleName] = useState('');
    const [saveLoading, setSaveLoading] = useState(false);
    const [loadingSale, setLoadingSale] = useState(false);
    const [, setDeleteLoading] = useState(false);
    const [filter, setFilter] = useState({ search: '', });
    const inputAmount = useRef();
    const inputSearchRef = useRef();
    const inputSellerRef = useRef();
    const currencyRefs = useRef([]);
    const [amount, setAmount] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [showSaleModal, setShowSaleModal] = useState(false);
    const [showCashierModal, setShowCashierModal] = useState(false);
    const [showExchangeModal, setShowExchangeModal] = useState(false);
    const [showPrintinvoice, setShowPrintinvoice] = useState(false);
    const [showCustomerFormModal, setShowCustomerFormModal] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [hasChange, setHasChange] = useState(false);
    const [disableRescueExchangeButton, setDisableRescueExchangeButton] = useState(false);
    const [isSaleEditable, setIsSaleEditable] = useState(true);
    const [productsSelected, setProductsSelected] = useState([]);
    const [salesForm, setSalesForm] = useState();
    const [saleUuid, setSaleUuid] = useState();
    const [showConfirmPrintNF, setShowConfirmPrintNF] = useState(false);
    const [selectedProdutcsToExchange, setSelectedProdutcsToExchange] = useState([]);
    const [showDevolutionModal, setShowDevolutionModal] = useState(false);

    const emptyFormDevolution = {
        products: [],
        customerCpf: '',
        storeId: '',
        saleOriginId: '',
        lineQuantity: '',
        itemsQuantity: '',
        total: '',
        status: '',
    };
    const [formDevolution, setFormDevolution] = useState(emptyFormDevolution);
    const [exchangeData, setExchangeData] = useState();
    const [couponData, setCouponData] = useState();
    const [couponTotal, setCouponTotal] = useState(0);

    const navigate = useNavigate();
    const handleRowClick = (route) => navigate(route);

    const canSave = user.roles?.includes(PermissionsEnum.CREATE_EXCHANGES);
    const canRemove = false;

    const [typeNF, setTypeNF] = useState();


    useEffect(() => {
        if (showPaymentModal) {
            setTimeout(() => {
                if (currencyRefs.current[0]) {
                    currencyRefs.current[0].focus();
                }
            }, 0);
        }
    }, [showPaymentModal]);


    useEffect(() => {
        async function fetchCollaboratorData() {
            try {
                const collaboratorData = await CashiersService.getByUuidCollaborator(user.uuid);
                if (Object.keys(collaboratorData).length === 0) {
                    setInfoModal({
                        title: 'Caixa não encontrado',
                        message: 'É necessário ter um caixa para realizar vendas. ',
                        style: InfoModalStyle.ERROR,
                        show: true,
                        onClose: () => {
                            navigate(getDashboardRoute());
                        },
                    });
                    return;
                }
                switch (collaboratorData?.status) {
                case 'CLOSED':
                    setShowCashierModal(true);
                    setShowSaleModal(false);
                    break;
                case 'OPEN':
                    setShowCashierModal(false);

                    break;
                default:
                    break;
                }
                setTypeNF(collaboratorData.typeNF);
            } catch (error) {
                console.error('Failed to fetch collaborator data:', error);
            }
        }

        if (!uuid) {
            fetchCollaboratorData();
        }

    }, [user.uuid, showSaleModal, showCashierModal]);

    useEffect(() => {
        async function fetchSale() {
            try {
                const sale = await SalesService.getSale(uuid);

                if (sale.payments.length <= 0) {
                    setIsSaleEditable(false);

                } else {
                    setIsSaleEditable(true);
                }
            } catch (error) {
                console.error('Failed to fetch sale:', error);
            }
        }

        const expectedPath = `/vendas/${uuid}`;
        const isNewSale = location.pathname === '/vendas/novo';

        if (uuid && location.pathname === expectedPath) {
            fetchSale();
        }
        else if (isNewSale) {
            setIsSaleEditable(false);

        }
    }, [uuid, location.pathname]);

    useEffect(() => {
        const isNewSale = location.pathname === '/vendas/novo';
        if (isNewSale) {
            setFormData(emptyFormData);
            setProductsSelected([]);
            setShowSaleModal(true);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (uuid) {
            setShowSaleModal(false);
            fetchSale();
        }
    }, [uuid]);

    useEffect(() => {
        setLoadingSale(loadingSale, true);
    }, [loadingSale]);

    useEffect(() => {
        let shortcutSearchSecondary;
        if (!isSaleEditable && (!showCustomerFormModal && !showSaleModal)) {

            shortcutSearchSecondary = addHotkey(ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY, () => {
                setShowModal(true);
            });
        }

        const shortcutReatrieve = addHotkey(ENUM_SHORTCUT.SHORTCUT_RETRIEVE, () => {
            if (!showCustomerFormModal && !showSaleModal)
                setShowExchangeModal(true);
        });

        return () => {
            if (shortcutSearchSecondary) removeHotkey(shortcutSearchSecondary);
            removeHotkey(shortcutReatrieve);
        };
    }, [isSaleEditable, showCustomerFormModal, showSaleModal]);

    useEffect(() => {
        const title = getTitle();
        const shortcutCancel = addHotkey(ENUM_SHORTCUT.SHORTCUT_CANCEL, () => {
            if (!showExchangeModal && !showSaleModal && title === 'Editar venda') {
                deleteSale();
            }
            if (showSaleModal) {
                inputSearchRef?.current?.focus();
                setShowSaleModal(false);
            }
            if (showCustomerFormModal) setShowCustomerFormModal(false);

        });
        return () => {
            removeHotkey(shortcutCancel);
        };
    }, [showSaleModal, showCustomerFormModal]);

    useEffect(() => {
        if (showPaymentModal) {
            const shortcutSearchSecondary = addHotkey(ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY, () => {
                const lastPayment = formData.payments[formData.payments.length - 1];
                const isLastPaymentEmpty = Object.values(lastPayment).every(value => value === '' || value === null || value === undefined);
                if (!isLastPaymentEmpty) {
                    addRowPayment();
                }
            });

            return () => {
                removeHotkey(shortcutSearchSecondary);
            };
        }
    }, [showPaymentModal, formData.payments]);

    useEffect(() => {
        if (formData.exchange) {
            fetchExchange(formData.exchange);
        }
    }, [formData.exchange]);

    useEffect(() => {
        if (formData.coupon) {
            fetchCoupon(formData.coupon);
        }
    }, [formData.coupon]);

    useEffect(() => {
        let couponTotal = 0;

        if (couponData) {
            if (couponData.type === 'fixed') {
                couponTotal = couponData.value;
            } else {
                couponTotal = (productsSelected.reduce(
                    (sum, pro) => sum + Number(pro.total),
                    0
                ) - exchangeTotal) * (couponData.value / 100);
            }
        }

        setCouponTotal(couponTotal);
    }, [couponData]);

    useEffect(() => {
        if (!productsSelected.length) {
            setHasChange(false);
        } else {
            setHasChange(true);
        }

        hasValidationError();


        const shortcutConfirm = addHotkey(ENUM_SHORTCUT.SHORTCUT_CONFIRM, async () => {
            if (productsSelected.length) {
                if (!formData.payments.length) {
                    addRowPayment();
                }
                setShowPaymentModal(true);
            }

            if (showSaleModal && salesForm) {

                inputSearchRef?.current?.focus();

                if (salesForm.customerCpf) {
                    updateFormData({ customerCpf: salesForm.customerCpf });
                }
                if (salesForm.seller) {
                    updateFormData({ seller: salesForm.seller });
                }
                setShowSaleModal(false);
            }

            if (salesForm && salesForm.customerCpf && !showCustomerFormModal && !formData.customerCpf) {
                await CustomerByCpf(salesForm.customerCpf);
            }

            if (showPaymentModal && !showPrintinvoice) {
                setShowPaymentModal(false);
                save(false);
            }

            if (!showPaymentModal && showPrintinvoice) {
                setShowPrintinvoice(true);
            }
        });

        return () => {
            removeHotkey(shortcutConfirm);
        };
    }, [formData, productsSelected, salesForm, showCustomerFormModal]);


    useEffect(() => {
        if (uuid && (formData.payments.length || formData.exchange)) {
            setDisableRescueExchangeButton(true);
            addHotkey(ENUM_SHORTCUT.SHORTCUT_RETRIEVE, () => {
                setShowExchangeModal(false);
            });
        } else {
            setDisableRescueExchangeButton(false);
            addHotkey(ENUM_SHORTCUT.SHORTCUT_RETRIEVE, () => {
                setShowExchangeModal(true);
            });
        }
    }, [formData.payments]);

    function updateFormData(data) {
        // noinspection JSCheckFunctionSignatures
        setFormData((formData) => ({ ...formData, ...data }));
        if (canSave) setHasChange(true);
    }

    function updateFormDevolution(data) {
        setFormDevolution((formDevolution) => ({ ...formDevolution, ...data }));
    }

    async function fetchSale() {
        try {
            setLoadingSale(true);
            const sale = await SalesService.getSale(uuid);

            setProductsSelected(sale.products);

            const payments = [];
            for (const p of sale.payments) {
                const { paymentType, machine, total, amountInstallments, quantityInstallments } = p;
                const pt = paymentType ? { id: paymentType, value: null } : null;
                const m = machine ? { id: machine, value: null } : null;

                payments.push({
                    quantityInstallments,
                    paymentType: pt,
                    machine: m,
                    total: total?.toLocaleString(undefined, {
                        style: 'currency',
                        currency: 'BRL'
                    }) ?? '',
                    amountInstallments: amountInstallments?.toLocaleString(undefined, {
                        style: 'currency',
                        currency: 'BRL'
                    }) ?? '',
                });
            }

            setFormData({
                ...sale,
                payments,
            });

            setSaleName(sale.clientName);
        } catch (e) {
            const title = getTitle();
            const { response } = e;
            if (response?.status === 404) {
                setInfoModal({
                    title,
                    message: 'Venda não encontrada.',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: back,
                });
            } else {
                backendConnectionError('Fail to fetch sale', e, null, title);
            }
        } finally {
            setLoadingSale(false);
        }
    }

    // async function fetchExchangeTotal() {
    //     try {
    //         const exchange = await ExchangesService.getExchangeTotal(formData.exchange);

    //         if (exchange) setExchangeTotal(exchange.total);

    //     } catch (e) {
    //         const title = getTitle();
    //         const { response } = e;
    //         if (response?.status === 404) {
    //             setInfoModal({
    //                 title,
    //                 message: 'Troca não encontrada.',
    //                 style: InfoModalStyle.ERROR,
    //                 show: true,
    //                 onClose: back,
    //             });
    //         } else {
    //             backendConnectionError('Fail to fetch exchange', e, null, title);
    //         }
    //     }
    // }

    async function fetchExchange(uuid) {
        try {
            const exchange = await ExchangesService.getExchange(uuid);

            if (exchange) {
                setExchangeData(exchange);
            }

        } catch (e) {
            const title = getTitle();
            const { response } = e;
            if (response?.status === 404) {
                setInfoModal({
                    title,
                    message: 'Devolução não encontrada.',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: back,
                });
            } else {
                backendConnectionError('Fail to fetch exchange', e, null, title);
            }
        }
    }

    async function fetchCoupon(uuid) {
        try {
            const coupon = await CouponService.getCouponByUUID({ uuid });

            if (coupon) {
                setCouponData(coupon);
            }

        } catch (e) {
            const title = getTitle();
            const { response } = e;
            if (response?.status === 404) {
                setInfoModal({
                    title,
                    message: 'Cupom não encontrada.',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: back,
                });
            } else {
                backendConnectionError('Fail to fetch coupon', e, null, title);
            }
        }
    }

    function removeRowPayment(index) {
        const total = productsSelected.reduce((sum, product) => sum + Number(product.total), 0) - exchangeTotal - couponTotal;
        const totalValue = currency(total, { precision: 2 });
        const numberOfPayments = formData.payments.length - 1;

        if (numberOfPayments <= 0) {
            updateFormData({ payments: [] });
            return;
        }

        const equalPayment = totalValue.divide(numberOfPayments);
        const payments = [];
        let remainingValue = totalValue;
        const formatReal = (value) => {
            return currency(value, { symbol: 'R$ ', decimal: ',', separator: '.' }).format();
        };
        const adjustedPayments = formData.payments.filter((_, i) => i !== index);
        for (let i = 0; i < numberOfPayments; i++) {
            let installmentValue = equalPayment;

            if (i === 0) {
                installmentValue = remainingValue.subtract(equalPayment.multiply(numberOfPayments - 1));
            }

            remainingValue = remainingValue.subtract(installmentValue);

            payments.push({
                ...emptyPayments,
                machine: adjustedPayments[i].machine || undefined,
                paymentType: adjustedPayments[i].paymentType || undefined,
                amountInstallments: formatReal(installmentValue),
                total: formatReal(installmentValue),
                quantityInstallments: 1,
            });

        }

        updateFormData({ payments: payments });
    }

    const exchangeTotal = exchangeData?.total || 0;

    function updateRowPayments() {
        if (!formData.payments.length) {
            return;
        }

        const total = productsSelected.reduce((sum, product) => sum + Number(product.total), 0) - exchangeTotal - couponTotal;
        const totalValue = currency(total, { precision: 2 });
        const numberOfPayments = formData.payments.length;
        const equalPayment = totalValue.divide(numberOfPayments);
        let remainingValue = totalValue;
        const formatReal = (value) => {
            return currency(value, { symbol: 'R$ ', decimal: ',', separator: '.' }).format();
        };

        const newPayments = formData.payments.map((payment, index) => {
            let installmentValue = equalPayment;

            if (index === 0) {
                installmentValue = remainingValue.subtract(equalPayment.multiply(numberOfPayments - 1));
            }

            remainingValue = remainingValue.subtract(installmentValue);

            return {
                ...emptyPayments,
                amountInstallments: formatReal(installmentValue),
                paymentType: payment?.paymentType || undefined,
                machine: payment?.machine || undefined,
                total: formatReal(installmentValue),
                quantityInstallments: 1,
            };
        });
        updateFormData({ payments: newPayments });
    }

    useEffect(() => {
        updateRowPayments();
    }, [couponTotal, exchangeTotal, productsSelected]);

    function addRowPayment() {
        const total = productsSelected.reduce((sum, product) => sum + Number(product.total), 0) - exchangeTotal - couponTotal;
        const totalValue = currency(total, { precision: 2 });
        const numberOfPayments = formData.payments.length + 1;
        const equalPayment = totalValue.divide(numberOfPayments);
        const payments = [];

        let remainingValue = totalValue;
        const formatReal = (value) => {
            return currency(value, { symbol: 'R$ ', decimal: ',', separator: '.' }).format();
        };

        for (let i = 0; i < numberOfPayments; i++) {
            let installmentValue = equalPayment;

            if (i === 0) {
                installmentValue = remainingValue.subtract(equalPayment.multiply(numberOfPayments - 1));
            }

            remainingValue = remainingValue.subtract(installmentValue);

            payments.push({
                ...emptyPayments,
                amountInstallments: formatReal(installmentValue),
                paymentType: formData.payments[i]?.paymentType || undefined,
                machine: formData.payments[i]?.machine || undefined,
                total: formatReal(installmentValue),
                quantityInstallments: 1,
            });
        }
        updateFormData({ payments: payments });
    }


    function back() {
        navigate(getSalesRoute());
    }

    function hasValidationError() {
        let hasError = false;
        setFormError(emptyFormError);
        setValidateOnChange(true);
        return hasError;
    }

    async function deleteSale() {
        setConfirmModal({
            title: 'Cancelar venda',
            message: 'Você tem certeza que deseja cancelar a venda?',
            onConfirm: proceed,
            show: true,
        });


        async function proceed() {
            try {
                setDeleteLoading(true);
                await SalesService.deleteSale(uuid);

                const message = 'Venda cancelada com sucesso!';
                const style = InfoModalStyle.SUCCESS;
                const onClose = back;
                setHasChange(false);
                setInfoModal({

                    title: 'Cancelar venda',
                    message,
                    style,
                    show: true,
                    onClose,
                });
            } catch (e) {
                backendConnectionError('Fail to delete sale', e, null, title);
            } finally {
                setDeleteLoading(false);
            }
        }
    }

    function getTitle(windowTitle = false) {
        if (uuid) {
            let title = 'Editar venda';
            if (windowTitle && saleName) title += ` - ${saleName}`;
            return title;
        } else {

            return 'Nova venda';
        }
    }

    const addProduct = useCallback(
        (product, isBarcodeSearch = false) => {
            const productFound = productsSelected.find(
                cartProduct => cartProduct.uuid === product.uuid,
            );
            const alreadyAdded = productFound?.amount || 0;
            if (Number(amount) <= 0) {
                setInfoModal({
                    title: 'Alerta',
                    message: 'Quantidade inválida!',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: () => {
                        setFilter({ ...filter, search: '' });
                    },
                });
                return;
            }

            if (!productFound) {
                setProductsSelected(prevProducts => [
                    ...prevProducts,
                    {
                        ...product,
                        amount: Number(amount),
                        total: product.price * Number(amount)
                    },
                ]);
            } else {
                setProductsSelected(prevProducts =>
                    prevProducts.map(product => {
                        if (productFound.uuid === product.uuid) {
                            return {
                                ...product,
                                amount: Number(product.amount) + Number(amount),
                                total: product.price * (Number(product.amount) + Number(amount))
                            };
                        }

                        return product;
                    }),
                );
            }
        },
        [amount, productsSelected],
    );

    const fetchProductsByBarCode = async () => {
        try {
            if (filter.search) {
                const prod = await ProductsService.getProductByBarCode(filter.search);
                if (prod.uuid) {
                    addProduct(prod, true);
                    setAmount(1);
                    setFilter({ ...filter, search: '' });
                } else {
                    setInfoModal({
                        title: 'Alerta',
                        message: 'Produto não encontrado na loja!',
                        style: InfoModalStyle.ERROR,
                        show: true,
                        onClose: () => {
                        },
                    });
                    setFilter({ ...filter, search: '' });
                }
            }
        } catch (e) {
            if (axios.isCancel(e)) {
                console.debug('Request cancelled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
            }
        }
    };

    const totalSale = (productsSelected.reduce((sum, product) => {
        return sum + Number(product.total);
    }, 0)) ?? 0;

    const totalPayable = (productsSelected.reduce((sum, product) => {
        return sum + Number(product.total);
    }, 0) - exchangeTotal - couponTotal) ?? 0;

    const totalItens = productsSelected.reduce((sum, product) => {
        return sum + Number(product.amount);
    }, 0) ?? 0;

    async function save(returnRoute) {
        if (saveLoading) return;
        if (hasValidationError()) return;

        const title = getTitle();
        try {
            setSaveLoading(true);

            const payments = [];
            for (const p of formData.payments) {
                const { paymentType, machine, total, amountInstallments } = p;
                const payment = {
                    ...p,
                    paymentType: paymentType?.id ?? null,
                    machine: machine?.id ?? null,
                    total: extractCurrencyNumber(total ?? ''),
                    amountInstallments: extractCurrencyNumber(amountInstallments ?? ''),
                };
                payments.push(payment);
            }

            const body = {
                ...formData,
                customerCpf: unmaskCpf(formData.customerCpf),
                printedNote: formData.printedNote ?? true,
                lineQuantity: productsSelected.length,
                itemsQuantity: productsSelected.reduce((sum, pro) => sum + Number(pro.amount), 0),
                total: productsSelected.reduce((sum, pro) => sum + Number(pro.total), 0) - exchangeTotal - couponTotal,
                cpfNote: unmaskCpf(formData.cpfNote),
                seller: salesForm?.seller?.id ?? null,
                exchange: formData.exchange ?? null,
                coupon: couponData?.uuid ?? null,
                products: productsSelected,
                payments: payments,
                cashier: user.uuid,
            };

            let message;
            if (!uuid) { // new sale
                let saleUUID = await SalesService.addSale(body);
                setSaleUuid(saleUUID);
                const onlyDinner = await checkPaymentIsDinnerOnly(formData.payments);
                if(onlyDinner) {
                    setShowConfirmPrintNF(true);
                }
                else{
                    createNFSale(saleUUID);
                }
                return;
            } else {
                await SalesService.editSale(uuid, body);

                message = 'Venda editada com sucesso!';
            }

            let style = InfoModalStyle.SUCCESS;
            let onClose = () => {
                navigate(0);
            };
            setHasChange(false);

            if (returnRoute) {
                setTimeout(() => {
                    navigate(getSalesRoute());
                }, 200);
            } else {
                setInfoModal({
                    title,
                    message,
                    style,
                    show: true,
                    onClose,
                });
            }
        } catch (e) {
            backendConnectionError('Fail to create/edit sale', e, null, title);
        } finally {
            setSaveLoading(false);
        }
    }

    async function checkPaymentIsDinnerOnly(payments) {
        const dinnerOnly = true;
        for (let p of payments) {
            if (p.paymentType?.type != PaymentsEnum.DINNER) {
                return false;
            }
        }
        return dinnerOnly;
    }


    async function createNFSale(sale) {
        if(typeNF == TypeNFSaleEnum.CFE) {
            await createCfe(sale);
            return;
        }
        await createNFCe(sale);
        return;
    }

    async function createNFCe(sale) {
        let onClose = () => {
            navigate(0);
        };
        const title = getTitle();
        try{
            let message = null;
            setLoading({ show: true });
            const response = await FocusApiService.createNFCe(sale);
            if(response.data) {
                printNF(response.data);
                message = 'Venda salva com sucesso!';
            } else {
                message = 'Venda salva com sucesso! Erro ao emitir NFCe.';
            }

            let style = InfoModalStyle.SUCCESS;
            setInfoModal({
                title,
                message,
                style,
                show: true,
                onClose,
            });
        }catch(e) {
            let message = 'Venda salva com sucesso! Erro ao emitir NFCe:';
            setInfoModal({
                title,
                message,
                style: InfoModalStyle.SUCCESS,
                show: true,
                onClose,
            });
        }
    }

    async function createCfe(sale) {
        let onClose = () => {
            navigate(0);
        };
        const title = getTitle();

        const cfeInfos = {
            bodyCfe: null,
            apiResponse: null,
            chaveNfe: null,
            sale: null,
            isSuccess: false,
        };

        try {
            setLoading({ show: true });
            const cfeData = await FocusApiService.getCfeBody(sale);
            cfeInfos.sale = sale;
            cfeInfos.bodyCfe = cfeData;

            const response = await FocusApiService.createCfe(cfeData, sale);
            cfeInfos.apiResponse = response.data;
            cfeInfos.chaveNfe = response.data.chave_nfe;
            cfeInfos.isSuccess = true;

            await printNF(response.data.chave_nfe);

            setHasChange(false);
            let message = 'Venda salva com sucesso!';
            let style = InfoModalStyle.SUCCESS;
            setInfoModal({
                title,
                message,
                style,
                show: true,
                onClose,
            });
        } catch (e) {
            cfeInfos.apiResponse = e.response?.data != null ? e.response?.data : e;
            cfeInfos.isSuccess = false;
            setHasChange(false);
            let message = 'Venda salva com sucesso! Erro ao emitir CFE: ' + (e.response?.data?.mensagem != undefined ? e.response?.data?.mensagem : e.message);
            setInfoModal({
                title,
                message,
                style: InfoModalStyle.SUCCESS,
                show: true,
                onClose,
            });
        } finally {
            try{
                await FocusApiService.saveResponseApi(cfeInfos);
            } catch(e) {
                console.log('Erro ao salvar dados do cfe: ' + e);
            }
            setLoading(false);
        }
    }

    async function printNF(chaveNfe) {
        if((formData.chaveNFe && formData.typeNF == TypeNFSaleEnum.CFE) || typeNF == TypeNFSaleEnum.CFE) {
            const responseCfePdf = await FocusApiService.getCfePdf(chaveNfe);
            const pdfBlob = new Blob([responseCfePdf.data], { type: 'application/pdf' });
            const pdfUrl = URL.createObjectURL(pdfBlob);
            window.open(pdfUrl);
            return;
        }
        const responseNfcePdf = await FocusApiService.getNFCePdf(chaveNfe);
        const pdfBlob = new Blob([responseNfcePdf.data], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl);
        return;
    }

    async function CustomerByCpf(cpf) {
        if (cpf) {
            const customer = await CustomerService.getCustomerByCpf(cpf);
            if (customer) {
                setFormData({
                    ...formData,
                    customerCpf: customer.cpf,
                    customerName: customer.name,
                });

            } else {

                setFormData({
                    ...formData,
                    customerCpf: cpf,

                });
            }
            setShowCustomerFormModal(true);
        }

    }

    async function addCustomerToSale(data) {
        try {
            await SalesService.addCustomerToSale({
                uuid: formData.uuid,
                customerCpf: formData.customerCpf
            });
            setHasChange(false);
            setInfoModal({
                title: 'Editar Venda',
                message: 'Cliente vinculado com sucesso!',
                style: InfoModalStyle.SUCCESS,
                show: true,
                onClose: () => {
                    navigate(0);
                },
            });
            return;
        } catch (e) {
            setInfoModal({
                title: 'Editar Venda',
                message: 'Erro ao vincular cliente!',
                style: InfoModalStyle.ERROR,
                show: true,
                onClose: () => {
                    navigate(0);
                },
            });
        }
    }

    const handleCheckboxProductsSelectedChange = (product) => {
        const isAlreadySelected = selectedProdutcsToExchange.some(
            (selected) => selected.uuid === product.uuid
        );

        if (isAlreadySelected) {
            setSelectedProdutcsToExchange(selectedProdutcsToExchange.filter((selected) => selected.uuid !== product.uuid));
        } else {
            setSelectedProdutcsToExchange([...selectedProdutcsToExchange, product]);
        }
    };

    useEffect(() => {
        updateFormDevolution({
            products: selectedProdutcsToExchange.map(
                (product) => {
                    return ({ ...product, amount: product.amountAvailableForReturn });
                }
            )
        });
    }, [selectedProdutcsToExchange]);

    const title = getTitle();

    async function saveDevolution() {
        if (saveLoading) return;
        try {
            setSaveLoading(true);
            const body = {
                products: formDevolution.products.filter(product => product.amount > 0),
                storeId: selectedStore.id,
                customerCpf: unmaskCpf(formData.customerCpf),
                saleOriginId: formData.id,
                lineQuantity: formDevolution.products.length,
                itemsQuantity: formDevolution.products.reduce((sum, product) => sum + Number(product.amount), 0),
                total: formDevolution.products.reduce((sum, product) => sum + Number(product.total), 0),
                status: ExchangeStatusEnum.AWAITING_RESCUE
            };

            let message;

            const chaveNFe = await ExchangesService.addExchange(body);
            message = chaveNFe ? 'Devolução salva com sucesso!' : 'Devolução salva com sucesso! Erro ao emitir NFe';

            let style = InfoModalStyle.SUCCESS;
            let onClose = () => {
                navigate(0);
            };
            setHasChange(false);
            setInfoModal({
                title: 'Devolução',
                message,
                style,
                show: true,
                onClose,
            });
        } catch (e) {
            backendConnectionError('Fail to create/edit exchange', e, null, 'Devolução', ValidationErrorExchangesText);
        } finally {
            setSaveLoading(false);
        }

    }

    useEffect(() => {
        setExchangeData(null);
        setCouponData(null);
    }, [uuid]);

    return (

        <>
            <RouteChangePrompt
                enabled={hasChange}
                message={
                    'Realmente deseja sair?'
                }

            />
            <div
                className={'crud-form sales-form'}
                style={{ height: 'calc(100vh - 40px)' }}
            >
                <div
                    className={'header'}
                    style={{ display: 'flex', flexDirection: 'column' }}
                >
                    <ScreenHeader
                        title={title}
                        breadcrumbs={[
                            { name: 'Vendas', route: getSalesRoute() },
                            { name: uuid ? (loadingSale ? '...' : 'Editar venda') : title },
                        ]}
                        backRoute={getSalesRoute()}
                    />
                </div>

                {
                    title === 'Nova venda' && (
                        <div className={'mb-28'}>
                            <div className={'row'}>
                                <div className={'col-4 d-flex'}>
                                    <div className={'quantity-field mr-4'}>
                                        <p> {'Quantidade:'} </p>
                                        <FieldInteger
                                            disabled={isSaleEditable}
                                            ref={inputAmount}
                                            value={amount}
                                            onChange={({ target }) =>
                                                setAmount(target.value)
                                            }
                                            thousandsSeparator={false}
                                            maxLength={4}
                                            height={'54px'}
                                            fieldGroup={false}
                                            className={'amount-field'}
                                        />
                                    </div>
                                    <FieldTextSearch
                                        disabled={isSaleEditable}
                                        ref={inputSearchRef}
                                        label={
                                            `<em>Buscar <strong>produto</strong></em> [${ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY}]`
                                        }
                                        value={filter.search}
                                        onChangeDebounce={fetchProductsByBarCode}
                                        onChange={({ target }) => {
                                            const value = target.value.replace(/\D/g, '');
                                            setFilter({
                                                ...filter,
                                                search: value,
                                            });

                                        }}
                                        className={'text_filter'}
                                    />
                                </div>
                                <div className={'col-8 align-right'}>
                                    <div className={'row col-12 align-right'}>
                                        {uuid && canRemove && (
                                            <div className={'col-3'}>
                                                <Button
                                                    disabled={isSaleEditable}
                                                    className={'w-100'}
                                                    buttonStyle={
                                                        ButtonStyle.BUTTON_SHADOW
                                                    }
                                                    color={
                                                        ButtonColor.BUTTON_COLOR_GRAY
                                                    }
                                                    onClick={deleteSale}

                                                >
                                                    {`Cancelar venda [${ENUM_SHORTCUT.SHORTCUT_CANCEL}]`}
                                                </Button>
                                            </div>
                                        )}
                                        <div className={'col-3'}>
                                            <Button
                                                className={'w-100'}
                                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                                fontColor={
                                                    ButtonFontColor.BUTTON_FONT_COLOR_LIGHT
                                                }
                                                onClick={() => {
                                                    if (formData.customerCpf) {
                                                        setShowExchangeModal(true);
                                                    } else {
                                                        setInfoModal({
                                                            title: 'Resgatar Devolução',
                                                            message: 'Necessário vincular cliente para resgatar devolução',
                                                            style: InfoModalStyle.ERROR,
                                                            show: true,
                                                        });
                                                    }
                                                }}
                                                disabled={isSaleEditable || exchangeData?.products?.length}
                                            >
                                                {`Resgatar devolução [${ENUM_SHORTCUT.SHORTCUT_RETRIEVE}]`}
                                            </Button>
                                        </div>
                                        <div className={'col-3'}>
                                            <Button
                                                className={'w-100'}
                                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                                fontColor={
                                                    ButtonFontColor.BUTTON_FONT_COLOR_LIGHT
                                                }
                                                onClick={() => {

                                                    if (productsSelected.length) {
                                                        if (!formData.payments.length)
                                                            addRowPayment();
                                                        setShowPaymentModal(true);
                                                        setTimeout(() => {
                                                            currencyRefs.current[0]?.focus();
                                                        }, 0);
                                                    }
                                                }}
                                                disabled={isSaleEditable || !productsSelected.length || totalPayable <= 0}
                                            >

                                                {`Finalizar venda [${ENUM_SHORTCUT.SHORTCUT_CONFIRM}]`}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    )
                }

                {
                    title === 'Editar venda' && (
                        <div className={'mb-24'}>
                            <div className={'row'}>
                                <div className={'col-8 align-right'}
                                    style={{ marginLeft: auto }}>
                                    <div className={'row col-12 align-right'}>
                                        <div className={'col-3'}>
                                            <Button
                                                className={'w-100'}
                                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                                fontColor={
                                                    ButtonFontColor.BUTTON_FONT_COLOR_LIGHT
                                                }
                                                onClick={() => {
                                                    if (formData.customerCpf) {
                                                        if (!formData.customerAddress) {
                                                            setShowCustomerFormModal(true);
                                                        } else {
                                                            setShowDevolutionModal(true);
                                                        }
                                                    } else {
                                                        setInfoModal({
                                                            title: 'Realizar Devolução',
                                                            message: 'Necessário vincular cliente para realizar devolução',
                                                            style: InfoModalStyle.ERROR,
                                                            show: true,
                                                        });
                                                    }
                                                }}
                                                disabled={!selectedProdutcsToExchange.length}
                                            >
                                                {`Realizar Devolução [${ENUM_SHORTCUT.SHORTCUT_RETRIEVE}]`}
                                            </Button>
                                        </div>
                                        <div className={'col-3'}>
                                            <Button
                                                className={'w-100'}
                                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                                fontColor={
                                                    ButtonFontColor.BUTTON_FONT_COLOR_LIGHT
                                                }
                                                onClick={() => {
                                                    if (formData?.chaveNfe) {
                                                        printNF(formData?.chaveNfe);
                                                    } else {
                                                        createNFSale(formData?.uuid);
                                                    }
                                                }}
                                                disabled={!isSaleEditable}
                                            >
                                                {'Reimprimir Cupom Fiscal'}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    )
                }

                <div className={'row mb-24'}>
                    <div className={'col-12 d-flex align-items-center'}>
                        <div className={'mr-24'}>
                            {
                                formData.customerCpf ? (
                                    <div>
                                        <span>
                                            <b>{'Cliente: '}</b>
                                            {`${formData.customerName ? `${formData.customerName} - ` : ''}${uuid ? formData.customerCpf : formatCpfMask(formData.customerCpf)}`}
                                        </span>
                                        {
                                            !formData.customerCpf || !isSaleEditable && (
                                                <span
                                                    className={'remove-customer clickable ml-8'}
                                                    onClick={() => {
                                                        setSalesForm((saleForm) => ({
                                                            ...saleForm,
                                                            cpfNote: null,
                                                            customerCpf: null,
                                                        }));
                                                        setExchangeData({});
                                                        updateFormData({ customerCpf: null, customerName: null, cpfNote: null, exchange: null });
                                                    }}
                                                >
                                                    <b>{'X'}</b>
                                                </span>
                                            )
                                        }

                                    </div>
                                ) :
                                    (
                                        <div>
                                            <span
                                                className={'clickable'}
                                                onClick={() => setShowSaleModal(true)}
                                            >
                                                <b>
                                                    <u>{'Vincular cliente'}</u>
                                                </b>
                                            </span>
                                        </div>
                                    )
                            }
                        </div>
                        {
                            uuid && (
                                <div>
                                    {
                                        couponData && (
                                            <span>
                                                <b>{'Cupom utilizado: '}</b>
                                                {'"'}{couponData.code}{'"'}{' com desconto de '}{formatValue(couponTotal)}
                                            </span>
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                </div>

                <div>
                    <div className={'table-container'}>
                        <table className={'products_header table'}>
                            <thead>
                                <tr>
                                    {
                                        title === 'Editar venda' ? (
                                            <>
                                                <th style={{ width: '10%' }}>{'Seleção'}</th>
                                                <th style={{ width: '5%' }}>{'Linha'}</th>
                                                <th
                                                    style={{ width: '40%' }}
                                                    className={'text-start'}
                                                >
                                                    {'Descrição do produto'}
                                                </th>
                                                <th>{'Quantidade'}</th>
                                                <th>{'Quantidade devolvida'}</th>
                                                <th>{'Preço unitário'}</th>
                                                <th>{'Total'}</th>
                                                <th>{'Devolução'}</th>
                                            </>
                                        ) :
                                            (
                                                <>
                                                    <th style={{ width: '5%' }}>{'Linha'}</th>
                                                    <th
                                                        style={{ width: '40%' }}
                                                        className={'text-start'}
                                                    >
                                                        {'Descrição do produto'}
                                                    </th>
                                                    <th>{'Quantidade'}</th>
                                                    <th>{'Preço unitário'}</th>
                                                    <th>{'Total'}</th>
                                                    <th style={{ width: '5%' }}></th>
                                                </>
                                            )
                                    }
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
                <div className={'middle-container'}>
                    <table className={'products_body'}>
                        <tbody>
                            {productsSelected.length ? (
                                productsSelected.map((product, index) => (
                                    <tr key={product.uuid}>
                                        {
                                            title === 'Editar venda' ? (
                                                <>
                                                    <td style={{ width: '10%' }}>
                                                        {product.amountAvailableForReturn > 0 &&
                                                            (
                                                                <input
                                                                    type={'checkbox'}
                                                                    checked={selectedProdutcsToExchange.some((selected) => selected.uuid === product.uuid)}
                                                                    onChange={() => handleCheckboxProductsSelectedChange(product)}
                                                                />
                                                            )
                                                        }
                                                    </td>
                                                    <td style={{ width: '5%' }}>
                                                        {index + 1}
                                                    </td>
                                                    <td
                                                        style={{ width: '40%' }}
                                                        className={'text-start'}
                                                    >
                                                        {product.name1}
                                                    </td>
                                                    <td>{product.amount}</td>
                                                    <td>{product.returnedAmount}</td>
                                                    <td>{formatValue(product.price)}</td>
                                                    <td>{formatValue(product.total)}</td>
                                                    <td>
                                                        {product.devolutions?.length ? product.devolutions?.map((devolution) => (
                                                            <Link key={devolution.id} target={'_blank'} to={getExchangesFormRoute(devolution.uuid)}>{devolution.id}</Link>
                                                        )) : '-'}
                                                    </td>
                                                </>

                                            ) : (
                                                <>
                                                    <td style={{ width: '5%' }}>
                                                        {index + 1}
                                                    </td>
                                                    <td
                                                        style={{ width: '40%' }}
                                                        className={'text-start'}
                                                    >
                                                        {product.name1}
                                                    </td>
                                                    <td>{product.amount}</td>
                                                    <td>{formatValue(product.price)}</td>
                                                    <td>{formatValue(product.total)}</td>
                                                    <td style={{ width: '5%' }}>
                                                        {!isSaleEditable && (
                                                            <ButtonRemove
                                                                onClick={() =>
                                                                    setProductsSelected((prevState) => {
                                                                        return prevState.filter(
                                                                            (selectedProduct) =>
                                                                                selectedProduct.uuid !== product.uuid
                                                                        );
                                                                    })
                                                                }
                                                            />
                                                        )}
                                                    </td>
                                                </>
                                            )
                                        }
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={6}>
                                        {'Nenhum produto adicionado'}
                                    </td>
                                </tr>
                            )}
                            {exchangeData?.products?.map((product) => (
                                <tr key={product.id} className={'products-exchange'}>
                                    {
                                        title === 'Editar venda' && (
                                            <td style={{ width: '10%' }}>
                                            </td>
                                        )
                                    }
                                    <td style={{ width: '5%' }}>
                                        {'Devolução: '}
                                    </td>
                                    <td
                                        style={{ width: '40%' }}
                                        className={'text-start'}
                                    >
                                        {product.name1}
                                    </td>
                                    <td>{product.amount}</td>
                                    {
                                        title === 'Editar venda' && (
                                            <td></td>
                                        )
                                    }
                                    <td>{formatValue(product.price)}</td>
                                    <td>{formatValue(product.total)}</td>
                                    <td style={{ width: '5%' }}>
                                        {!isSaleEditable && (
                                            <ButtonRemove
                                                onClick={() => {
                                                    setExchangeData({});
                                                    updateFormData({ exchange: null });
                                                }
                                                }
                                            />
                                        )}
                                    </td>
                                </tr>
                            ))
                            }
                        </tbody>
                    </table>
                </div>

                <div className={'mt-8 row'}>
                    <div className={'col-12 totals__container'}>
                        <div className={'totals__wrapper mr-6'}>
                            <div className={'d-flex'}>
                                <Amount
                                    title={'Qtd produtos'}
                                    amount={productsSelected.length}
                                    radius={'12px 0px 0px 12px'}
                                    className={'amount-sale mr-6'}
                                    inline
                                />
                                <Amount
                                    title={'Total a pagar'}
                                    amount={formatValue(totalSale)}
                                    radius={'0px 0px 0px 0px'}
                                    className={'amount-sale'}
                                />
                            </div>
                            <div className={'d-flex mt-6'}>
                                <Amount
                                    title={'Qtd itens'}
                                    amount={totalItens}
                                    radius={'12px 0px 0px 12px'}
                                    className={'amount-sale mr-6'}
                                    inline
                                />
                                <Amount
                                    title={'Devolução'}
                                    amount={formatValue(exchangeTotal)}
                                    radius={'0px 0px 0px 0px'}
                                    className={'amount-sale'}
                                />
                            </div>
                        </div>
                        <div className={'totals__sale'}>
                            <p>{'Total da compra'}</p>
                            <span>{formatValue(totalPayable)}</span>
                        </div>
                    </div>
                </div>
            </div>
            <CashierOpeningModal
                show={showCashierModal}
                setShowSaleModal={setShowSaleModal}
                setShowCashierModal={setShowCashierModal}
                inputSellerRef={inputSellerRef}
                onCancel={() => {
                    setShowCashierModal(false);
                    navigate(getDashboardRoute());
                }}
            />

            <CustomersFormModal
                setData={setFormData}
                show={showCustomerFormModal && !showCashierModal}
                nameIsRequired={true}
                addressIsRequired={title === 'Editar venda'}
                value={formData.customerCpf || formData.cpfNote}
                onCancel={() => {
                    if (title === 'Editar venda') {
                        setShowCustomerFormModal(false);
                    } else {
                        updateFormData({ customerCpf: null, customerName: null });
                        setShowCustomerFormModal(false);
                        inputSearchRef?.current?.focus();
                    }
                }
                }
                disabledCPF={true}
                onConfirm={(customer) => {
                    setShowCustomerFormModal(false);

                    if (title === 'Editar venda') {
                        setShowDevolutionModal(true);
                    } else {
                        if (customer) {
                            updateFormData({ customerCpf: customer.cpf, customerName: customer.name, customerId: customer.id });
                            inputSearchRef?.current?.focus();
                            if (isSaleEditable) {
                                addCustomerToSale({
                                    uuid: formData.uuid,
                                    customerCpf: formData.customerCpf,
                                    customerName: formData.customerName,
                                });
                            }
                        }
                    }

                }}
            />

            <SalesModal
                isSaleEditable={isSaleEditable}
                inputSellerRef={inputSellerRef}
                show={showSaleModal}
                onCancel={() => {
                    setShowSaleModal(false);
                    inputSearchRef?.current?.focus();
                }}
                onChangeForm={(form) => {
                    setSalesForm(form);
                }}
                onConfirm={(target) => {
                    updateFormData({ customerCpf: target.formData?.customerCpf, cpfNote: target.formData?.customerCpf, seller: target.formData?.seller });
                    if (target.formData.customerCpf) {
                        CustomerByCpf(unmaskCpf(target.formData.customerCpf)).then(() => {
                            setShowCustomerFormModal(true);
                        });
                    }
                    setShowSaleModal(false);
                    inputSearchRef?.current?.focus();
                }}
            />


            <SearchProductModal
                show={showModal && !showCashierModal}
                onCancel={() => setShowModal(false)}
                onSelect={(prod) => {
                    if (prod) {
                        addProduct(prod);
                        setAmount(1);
                        setShowModal(false);
                    }
                }}
            />

            <SalesPaymentModal
                setFormData={setFormData}
                show={showPaymentModal && !showCashierModal}
                currencyRefs={currencyRefs}
                formData={formData}
                salesForm={salesForm}
                setSalesForm={setSalesForm}
                amount={productsSelected.reduce(
                    (sum, pro) => sum + Number(pro.total),
                    0
                )}
                couponTotal={couponTotal}
                setCouponTotal={setCouponTotal}
                couponData={couponData}
                setCouponData={setCouponData}
                exchangeTotal={exchangeTotal}
                updateFormData={updateFormData}
                addRowPayment={addRowPayment}
                removeRowPayment={removeRowPayment}
                onCancel={() => setShowPaymentModal(false)}
                onConfirm={() => {
                    setShowPaymentModal(false);
                    save(false);
                }}
            />

            <SearchExchangeModal
                show={showExchangeModal}
                onCancel={() => setShowExchangeModal(false)}
                searchValue={formData?.customerCpf}
                onSelect={(target) => {
                    if (target) {
                        updateFormData({ exchange: target.uuid });
                        setShowExchangeModal(false);
                    }
                }}
            />

            <ConfirmPrintNF
                show={showConfirmPrintNF}
                onCancel={() => {
                    setShowConfirmPrintNF(false);
                    setHasChange(false);
                    let message = 'Venda salva com sucesso!';
                    let style = InfoModalStyle.SUCCESS;
                    setInfoModal({
                        title: getTitle(),
                        message,
                        style,
                        show: true,
                        onClose: () => navigate(0),
                    });
                }}
                onConfirm={() => {
                    createNFSale(saleUuid);
                    setShowConfirmPrintNF(false);
                }}
            />

            <DevolutionSaleModal
                show={showDevolutionModal}
                onCancel={() => setShowDevolutionModal(false)}
                onConfirm={() => saveDevolution()}
                formData={formData}
                formDevolution={formDevolution}
                updateFormDevolution={updateFormDevolution}
            />

        </>
    );
}

export function getSalesFormRoute(uuid) {
    if (uuid) {
        return `/vendas/listagem/${uuid}`;
    } else {
        return '/vendas/novo';
    }
}
