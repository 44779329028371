import name from "./name";
import email from "./email";
import cpf from "./cpf";
import cnpj from "./cnpj";
import address from "./address";
import phone from "./phone";
import sensitive from "./sensitive";
import date from "./date";
import cep from "./cep";

export const hideData = {
  name,
  email,
  cpf,
  cnpj,
  address,
  phone,
  sensitive,
  date,
  cep,
};
