import api from '../util/api';
import { formatedDateForFetchAPi as formattedDateForFetchAPi } from '../util/utilDate';

export async function getCashiersMovement(
    page,
    search,
    order,
    cancelToken
) {
    return (await api.get('/cashier', {
        params: {
            page: 0,
        },
        cancelToken: cancelToken
    })).data;
}

export async function getCashierForUser({
    uuid,
}) {
    return (await api.get(`/cashier/collaborator/${uuid}`)).data;
}

export async function getCashMovements({ date, userId }) {
    const { startOfDay, endOfDay } = formattedDateForFetchAPi({ date });
    const result = (await api.get('/cash-movements', {
        params: {
            startDate: startOfDay,
            endDate: endOfDay,
            userId,
        }
    })).data;
    return result;
}

export async function getMachineValues({ date, userId }) {
    const { startOfDay, endOfDay } = formattedDateForFetchAPi({ date });

    const data = (await api.get('/cash-movements/machine-values', {
        params: {
            startDate: startOfDay,
            endDate: endOfDay,
            userId,
        }
    })).data;

    const transformData = (data) => {
        return data.map((entry) => {
            const { name, payments } = entry;
            const result = payments.reduce(
                (acc, { paymentType, total }) => {
                    acc[paymentType] = (acc[paymentType] || 0) + total;
                    acc.total += total;
                    return acc;
                },
                { total: 0 }
            );

            return { name, ...result };
        });
    };

    const result = transformData(data);
    return result;
}

export async function getSummaryMovement({ storeId, date }) {
    const { startOfDay, endOfDay } = formattedDateForFetchAPi({ date });
    const result = (await api.get('/cash-movements/summary-movements', {
        params: {
            startDate: startOfDay,
            endDate: endOfDay,
            storeId,
        }
    })).data;   
    return result;    
}

