import api from '../util/api';

export async function getNotifications() {
    return (
        await api.get('/notifications')
    ).data;
}

export async function viewNotification(id) {
    return (
        await api.put(`/notifications/view/${id}`)
    ).data;
}

export async function viewAllNotifications() {
    return (
        await api.put('/notifications/view')
    ).data;
}

