import { useContext, useEffect, useRef, useState } from 'react';
import Button, { ButtonColor, ButtonStyle } from '../../components/Button';
import { IconAdd, IconAdd2x, IconFilter, IconFilter2x } from '../../components/images';
import ScreenHeader from '../../components/logged/ScreenHeader';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import { useNavigate } from 'react-router';
import * as AccountsService from '../../services/AccountsService';
import { AccountBankText, AccountTypeEnum, AccountTypeText, ENUM_SHORTCUT } from 'erva-doce-common';
import { DateTime } from 'luxon';
import AccountsFilterModal from './AccountsFilterModal';
import { getAccountsFormRoute } from './AccountsForm';
import { EnvironmentContext } from '../../contexts/EnviromentContext';

const INITIAL_FILTER = {
    page: 0,
    order: null,
};

export default function Accounts() {
    const title = 'Contas bancárias';
    const navigate = useNavigate();

    const [showFilter, setShowFilter] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [filter, setFilter] = useState(INITIAL_FILTER);
    const [data, setData] = useState({});
    const { addHotkey, removeHotkey } = useContext(EnvironmentContext);
    const filterBtnRef = useRef();

    const fetchInfo = async () => {
        try {
            setIsLoading(true);

            const res = await AccountsService.get(filter);

            setData(res);
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {

        const shortcutCreatePrimary = addHotkey(ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY, () => {
            navigate(getAccountsFormRoute());
        });

        const shortcutFilter = addHotkey(ENUM_SHORTCUT.SHORTCUT_FILTER, () => {
            if(filterBtnRef.current) {
                filterBtnRef.current.click();
            }
        });

        return () => {
            removeHotkey(shortcutCreatePrimary);
            removeHotkey(shortcutFilter);
        };
    }, []);

    useEffect(() => {
        fetchInfo();
    }, [filter]);

    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Financeiro', route: '/' },
                        { name: title, route: getAccountsRoute() },
                    ]}
                    hideStore
                />
                <div className={'controls'}>
                    <div className={'gd'}>
                        <div className={'gd-col gd-col-8'}></div>
                        <div className={'gd-col gd-col-2'}>
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                icon={IconAdd}
                                icon2x={IconAdd2x}
                                route={getAccountsFormRoute()}
                            >
                                {`Nova conta [${ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY}]`}
                            </Button>
                        </div>
                        <div className={'gd-col gd-col-2'}>
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                color={ButtonColor.BUTTON_COLOR_ORANGE}
                                icon={IconFilter}
                                icon2x={IconFilter2x}
                                onClick={() => setShowFilter(true)}
                                ref={filterBtnRef}
                            >
                                {`Filtrar [${ENUM_SHORTCUT.SHORTCUT_FILTER}]`}
                            </Button>
                        </div>

                    </div>
                </div>
                <div>
                    <Table
                        loading={isLoading}
                        currentSort={filter.order}
                        columns={[
                            {
                                name: 'Banco',
                            },
                            {
                                name: 'Tipo de conta',
                                align: 'center',
                            },
                            {
                                name: 'Documento',
                                align: 'center',
                            },
                            {
                                name: 'Agência',
                                align: 'center',
                            },
                            {
                                name: 'Número de conta',
                                align: 'center',
                            },
                            {
                                name: 'Data',
                                align: 'center',
                            },
                        ]}
                    >
                        {data?.records?.map((item, index) => (
                            <tr
                                className={'table-row'}
                                key={index}
                                onClick={() => navigate(getAccountsFormRoute(item.uuid))}
                            >
                                <td className={'text-center'}>{AccountBankText(item.bank)}</td>
                                <td className={'text-center'}>{AccountTypeText(item.accountType)}</td>
                                <td className={'text-center'}>{item.accountType === AccountTypeEnum.PF ? item.document : item.document}</td>
                                <td className={'text-center'}>{item.agency}</td>
                                <td className={'text-center'}>{item.accountNumber}</td>
                                <td className={'text-center'}>{DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy')}</td>
                            </tr>
                        ))}
                    </Table>
                    <Pagination
                        page={data?.page}
                        pageSize={data?.pageSize}
                        count={data?.count}
                        recordCount={data?.records?.length || 0}
                        onPageChange={(page) => setFilter({ ...filter, page })}
                    />
                </div>
            </div>
            <AccountsFilterModal
                show={showFilter}
                onConfirm={() => {
                    setShowFilter(false);
                }}
                onCancel={() => {
                    setShowFilter(false);
                }}
            />
        </>
    );
}

export function getAccountsRoute() {
    return '/financeiro/contas-bancarias';
}
