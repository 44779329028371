import api from '../util/api';

export async function getAllCoupons({ filter }) {
    try {
        const res = (await api.get('/coupon?page=0')).data;
        res.records = res.records.map((coupon) => {
            return {
                id: coupon.uuid,
                code: coupon.code,
                dtCriação: new Date(coupon.createdAt).toLocaleDateString(),
                isActive: coupon.isActive,
                type: coupon.type,
                value: coupon.value,
                minValue: coupon.minValue,
                isOnlyEcommerce: coupon.isOnlyEcommerce,
                availableInStore: coupon.availableInStore,
            };
        });
        return res;
    } catch (error) {
        return {};
    }
}

export async function getCouponByUUID({ uuid }) {
    const res = (await api.get(`/coupon/${uuid}`)).data;
    const data = {
        id: res.id,
        uuid: res.uuid,
        code: res.code,
        isActive: res.isActive,
        freeShipping: res.freeShipping,
        type: res.type,
        typeFixed: res.typeFixed,
        typePercent: res.typePercent,
        value: res.value,
        minValue: res.minValue,
        description: res.description,
        userList: res.users,
        isOnlyEcommerce: res.isOnlyEcommerce,
        availableInStore: res.availableInStore,
    };
    return data;
}

export async function createANewCoupon({ formData }) {
    return (await api.post('/coupon', formData));
}

export async function updateCoupon({ formData, uuid }) {
    return (await api.put(`/coupon/${uuid}`, formData));
}

export async function getCustomerByCpf({ cpf }) {
    return (await api.get(`/customers/${cpf}`)).data;
}

export async function validateCoupon(code, customerId) {
    return (await api.get(`/coupon/${code}/validate/${customerId}`)).data;
}


export async function getCouponUsage({ couponUuid }) {
    try {
        return (await api.get(`/coupon/usage/${couponUuid}`)).data;
    } catch (error) {
        console.error(`Erro ao fazer requisição ${error}`);
    }
}