import { useState, useEffect, useId } from 'react';
import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import FieldCpf from '../../components/FieldCpf';
import { isValidCpf } from 'erva-doce-common';
import { getCustomerByCpf } from '../../services/CouponService';
import './userCPFModal.scss';
import CustomersFormModal from '../customers/CustomersFormModal';
import { EnumCouponStatusUser } from 'erva-doce-common';

export default function UserCPFModal({
    showModal,
    onCloseModal,
    setUser,
    userList,
}) {
    const title = 'Adicione um cliente para esse cupom!';
    const [showCustomerFormModal, setShowCustomerFormModal] = useState(false);
    const [cpfDataToAddUser, setCpfDataToAddUser] = useState();

    const [cpfData, setCpfData] = useState({
        cpfField: '',
        messageError: '',
        user: null,
        cpfInvalid: false,
        userNotFound: false,
        duplicatedUser: false,
    });

    useEffect(() => {
        if (!showModal) {
            handleResetStates();
        }
    }, [showModal]);

    const handleResetStates = () => {
        setCpfData({
            cpfField: '',
            messageError: '',
            user: null,
            cpfInvalid: false,
            userNotFound: false,
            duplicatedUser: false,

        });
    };

    const handleCpfChange = (e) => {
        setCpfData({
            ...cpfData,
            cpfField: e.target.value,
            cpfInvalid: false,
            userNotFound: false,
            duplicatedUser: false,
            user: null,
        });
    };

    const submit = async () => {
        if (!isValidCpf(cpfData.cpfField)) {
            setCpfData({ ...cpfData, cpfInvalid: true });
            return;
        }

        try {
            const res = await getCustomerByCpf({ cpf: cpfData.cpfField });
            setCpfData({
                ...cpfData,
                user: res,
                userNotFound: !res,
            });
        } catch (error) {
            console.error('Erro ao pesquisar cliente', error);
            setCpfData({
                ...cpfData,
                messageError: 'Erro ao pesquisar cliente',
            });
        }
    };

    const addUserToCoupon = () => {
        const lengthUser = userList?.length;
        const { id, cpf, name } = cpfData.user;
    
        if (lengthUser) {
            const userExists = userList.findIndex(
                (user) => user.cpf === cpf && user.status === EnumCouponStatusUser.ACTIVED
            );
    
            if (userExists !== -1) {
                setCpfData({ ...cpfData, duplicatedUser: true, cpfInvalid: false });
                return;
            }
        }
    
        setUser({ id, cpf, name, status: EnumCouponStatusUser.ACTIVED });
        closeModal();
    };
    

    const openAddUserModal = () => {
        setShowCustomerFormModal(true);
        setCpfDataToAddUser(cpfData.cpfField);
        closeModal();
    };

    const closeModal = () => {
        handleResetStates();
        onCloseModal(false);
    };

    const handleRequestCompleted = ({ newUser }) => {
        const { id, cpf, name } = newUser;
        setUser({ id, cpf, name, status: EnumCouponStatusUser.ACTIVED });
    };

    return (
        <>
            <SimpleConfirmModal
                title={title}
                show={showModal}
                onCancel={() => closeModal()}
                cancelText={'Cancelar'}
                onConfirm={submit}
                confirmText={'Pesquisar'}

            >
                <FieldCpf
                    label={'CPF do cliente'}
                    placeholder={'000.000.000-00'}
                    onChange={handleCpfChange}
                    validationError={cpfData.messageError}
                    value={cpfData.cpfField || ''}
                />
                <div>
                    {cpfData.user && (
                        <div className={'d-flex justify-content-between'}>
                            <p>{`Adicionar ${cpfData?.user?.name ? `${cpfData?.user?.name} ` : ''}(${cpfData?.user.cpf}) para este cupom?`}</p>
                            <button
                                className={'btn-link-add-user-to-coupon'}
                                onClick={addUserToCoupon}
                            >
                                {'SIM'}
                            </button>
                        </div>
                    )}

                    {cpfData.cpfInvalid && (
                        <p className={'text-center cpf-invalid mt-12'}>
                            {'Digite um CPF válido'}
                        </p>
                    )}

                    {cpfData.duplicatedUser && (
                        <p className={'text-center cpf-invalid mt-12'}>
                            {'Não é possível adicionar um usuário que já está na lista.'}
                        </p>
                    )}

                    {cpfData.userNotFound && (
                        <div className={'d-flex justify-content-between mt-12'}>
                            <p>
                                {
                                    'Este cliente não existe em nossa base de dados'
                                }
                            </p>
                            <button
                                className={'btn-link-user-not-existis'}
                                onClick={() => openAddUserModal()}
                            >
                                {'Cadastrar'}
                            </button>
                        </div>
                    )}
                </div>
            </SimpleConfirmModal>
            <CustomersFormModal
                show={showCustomerFormModal}
                value={cpfDataToAddUser}
                onCancel={() => setShowCustomerFormModal(false)}
                disabledCPF={true}
                onConfirm={(customer) => {
                    setShowCustomerFormModal(false);
                    handleRequestCompleted({ newUser: customer });
                }}
                nameIsRequired={true}
                phoneIsRequired={true}
            />
        </>
    );
}
