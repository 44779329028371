import { CfopEnum } from "./cfop.enum";

export enum DevolutionInfosEnum {
    DESC_NFE = "Devolução pelo cliente",
    TIPO_DOCUMENTO = 0,
    FINALIDADE_EMISSAO = 4,
    LOCAL_DESTINO = 1,
    CONSUMIDOR_FINAL = 1,
    PRESENCA_COMPRADOR = 1,
    REGIME_TRIBUTARIO_EMITENTE = 1,
    MODALIDADE_FRETE = 9,
    ICMS_ORIGEM = "0",
    PIS_SITUACAO_TRIBUTARIA = "49",
    COFINS_SITUACAO_TRIBUTARIA = "49"
}


export function getCfopDevolutionByCfopSale(cfop) {
    const cfopSale = CfopEnum[cfop]
    switch (cfopSale) {
        case "CFOP_5102":
            return "1202";
        case "CFOP_5405":
            return "1411";
    }
}


