import * as Sentry from '@sentry/react';
import axios from 'axios';
import {
    ENUM_SHORTCUT,
    OrderByUsersEnum,
    PermissionsEnum,
} from 'erva-doce-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Button, { ButtonColor, ButtonStyle } from '../../components/Button';
import ButtonBadge from '../../components/ButtonBadge';
import FieldTextSearch from '../../components/FieldTextSearch';
import LoadError from '../../components/LoadError';
import Pagination from '../../components/Pagination';
import Picture from '../../components/Picture';
import Table from '../../components/Table';
import { IconAdd, IconAdd2x, IconFilter, IconFilter2x } from '../../components/images';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as CollaboratorsService from '../../services/CollaboratorsService';
import { createCancelTokenSource } from '../../util/api';
import { getCollaboratorFormRoute } from './CollaboratorForm';
import CollaboratorsFilterModal from './CollaboratorsFilterModal';

export default function Collaborators() {
    const [collaborators, setCollaborators] = useState(null);
    const [collaboratorsLoading, setUserLoading] = useState(null);
    const [filter, setFilter] = useState({
        order: OrderByUsersEnum.NAME_ASC,
        search: null,
        page: 0,
        jobsTitles: [],
        stores: [],
    });
    const inputSearchRef = useRef();
    const {
        addHotkey,
        removeHotkey,
        user,
    } = useContext(EnvironmentContext);
    const [showFilter, setShowFilter] = useState(false);

    const canAdd = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_USERS);
    const navigate = useNavigate();
    const handleRowClick = (route) => navigate(route);
    const filterBtnRef = useRef();

    useEffect(() => {
        fetchCollaborators();
    }, [filter]);

    useEffect(() => {
        const shortcutSearchSecondary = addHotkey(ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY, () => {
            inputSearchRef.current?.focus();
        });

        const shortcutCreatePrimary = addHotkey(ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY, () => {
            navigate(getCollaboratorFormRoute());
        });

        const shortcutFilter = addHotkey(ENUM_SHORTCUT.SHORTCUT_FILTER, () => {
            if(filterBtnRef.current) {
                filterBtnRef.current.click();
            }
        });

        return () => {
            removeHotkey(shortcutSearchSecondary);
            removeHotkey(shortcutCreatePrimary);
            removeHotkey(shortcutFilter);
        };
    }, []);

    async function fetchCollaborators() {
        if (collaboratorsLoading?.cancel) {
            collaboratorsLoading.cancel();
        }
        try {
            const cancelTokenSource = await createCancelTokenSource();
            setUserLoading(cancelTokenSource);
            const response = await CollaboratorsService.getCollaborators(
                filter.page,
                filter.search,
                filter.jobsTitles?.map(record => record.id),
                filter.stores?.map(record => record.id),
                filter.order,
                cancelTokenSource.token
            );
            setCollaborators(response);
            setUserLoading(null);
        } catch (e) {
            if (axios.isCancel(e)) {
                console.debug('Request cancelled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
                setUserLoading(e);
                setCollaborators(null);
            }
        }
    }

    function renderTable() {
        if (collaboratorsLoading && collaboratorsLoading instanceof Error) {
            return (
                <LoadError
                    // I18N
                    message={'Não foi possível carregar os colaboradores'}
                    onTryAgain={fetchCollaborators}
                />
            );
        }

        let rows = [];
        // noinspection JSUnresolvedReference
        const records = collaborators?.records ?? [];
        for (const user of records) {
            rows.push(
                <tr key={user.uuid} onClick={() => handleRowClick(getCollaboratorFormRoute(user.uuid))}>
                    <td className={'center'}>
                        <Picture
                            name={user.name}
                            picture={user.picture}
                        />
                    </td>
                    <td>
                        {user.name}
                    </td>
                    <td>
                        {user.jobTitle ?? '-'}
                    </td>
                    <td>
                        {user?.stores?.length ? user.stores[0].fantasyName : '-'}
                    </td>
                    <td>
                        {user.status ?? '-'}
                    </td>
                    <td>
                        {/*TODO*/}
                    </td>
                </tr>
            );
        }

        return (
            <>
                <Table
                    loading={collaboratorsLoading}
                    currentSort={filter.order}
                    columns={[
                        {}, // picture column
                        {
                            // I18N
                            name: 'Colaborador',
                            sortAsc: OrderByUsersEnum.NAME_ASC,
                            sortDesc: OrderByUsersEnum.NAME_DESC,
                            onSortChange: (order) => setFilter({ ...filter, order }),
                        }, {
                            // I18N
                            name: 'Cargo',
                            sortAsc: OrderByUsersEnum.JOB_TITLE_ASC,
                            sortDesc: OrderByUsersEnum.JOB_TITLE_DESC,
                            onSortChange: (order) => setFilter({ ...filter, order })
                        }, {
                            // I18N
                            name: 'Loja',
                            sortAsc: OrderByUsersEnum.STORE_ASC,
                            sortDesc: OrderByUsersEnum.STORE_DESC,
                            onSortChange: (order) => setFilter({ ...filter, order })
                        }, {
                            // I18N
                            name: 'Status',
                        }, {
                            // I18N
                            name: 'Tela ativa',
                        },
                    ]}>
                    {rows}
                </Table>
                <Pagination
                    page={collaborators?.page}
                    pageSize={collaborators?.pageSize}
                    count={collaborators?.count}
                    recordCount={collaborators?.records?.length}
                    onPageChange={page => setFilter({ ...filter, page })}
                />
            </>
        );
    }

    const filterBadgeCount =
        (filter?.jobsTitles?.length ?? 0) +
        (filter?.stores?.length ?? 0);

    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={'Colaboradores'}
                    breadcrumbs={[
                        { name: 'Administrativo', route: getDashboardRoute() },
                        { name: 'Colaboradores', route: getCollaboratorsRoute() },
                    ]}
                    hideStore
                />
                <div className={'controls'}>
                    <div className={'gd'}>
                        <div className={`gd-col ${canAdd ? 'gd-col-6' : 'gd-col-9'}`}>
                            <FieldTextSearch
                                ref={inputSearchRef}
                                // I18N
                                label={`<em>Buscar por <strong>colaborador</strong> [${ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY}]</em>`}
                                onChange={({ target }) =>
                                    setFilter({ ...filter, search: target.value })
                                }
                            />

                        </div>
                        {canAdd && (
                            <div className={'gd-col gd-col-3'}>
                                <Button
                                    buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                    icon={IconAdd}
                                    icon2x={IconAdd2x}
                                    route={getCollaboratorFormRoute()}
                                >
                                    {`Novo colaborador [${ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY}]`}
                                </Button>
                            </div>
                        )}
                        <div className={'gd-col gd-col-3'}>
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                color={ButtonColor.BUTTON_COLOR_ORANGE}
                                icon={IconFilter}
                                icon2x={IconFilter2x}
                                onClick={() => setShowFilter(true)}
                                badgeChildren={!!filterBadgeCount && <ButtonBadge count={filterBadgeCount} />}
                                ref={filterBtnRef}
                            >
                                {`Filtrar [${ENUM_SHORTCUT.SHORTCUT_FILTER}]`}
                            </Button>
                        </div>
                    </div>
                </div>

                <div className={'table-scroll'}>
                    {renderTable()}
                </div>
            </div>
            <CollaboratorsFilterModal
                show={showFilter}
                onCancel={() => setShowFilter(false)}
                filter={filter}
                onConfirm={(complementaryFilter) => {
                    setShowFilter(false);
                    // noinspection JSCheckFunctionSignatures
                    setFilter({
                        ...filter,
                        ...complementaryFilter,
                    });
                }}
            />
        </>
    );
}

export function getCollaboratorsRoute() {
    //I18N
    return '/administrativo/colaboradores';
}
