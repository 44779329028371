import { useEffect, useState } from 'react';

function _getWindowSize() {
    if (window) {
        const width = window.outerWidth;
        const height = window.outerHeight;

        return {
            width,
            height,
        };
    }

    return {
        width: 0,
        height: 0,
    };
}

export default function useWindowResize() {
    const [isClient, setIsClient] = useState(false);
    const [windowSize, setWindowSize] = useState({ width: 1140, height: 200 });

    useEffect(() => {
        setWindowSize(_getWindowSize());
    }, [isClient]);

    useEffect(() => {
        setIsClient(true);

        const handleWindowResize = () => {
            setWindowSize(_getWindowSize());
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return windowSize;
}
