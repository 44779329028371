import api from '../util/api';
import { v4 as uuidV4 } from 'uuid';

export async function getProducts(id, filters) {
    return (
        await api.get(`/supplier-representative/${id}/products`, {
            params: filters,
        })
    ).data;
}

export async function getById(id) {
    return (
        await api.get(`/supplier-representative/${id}`)
    ).data;
}

export async function download({ id, fileName }) {
    try {
        const response = await api.get(`/supplier-representative/${id}/products/download`, {
            responseType: 'arraybuffer'
        });

        const { data } = response;
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${fileName}.xlsx`;
        link.click();
        URL.revokeObjectURL(link.href);
    } catch (error) {
        console.log(error);
        console.error('Erro ao baixar planilha');
    }
}

export async function addProduct(id, uuid) {
    return await api.put(`/supplier-representative/${id}/products`, { uuid });
}


export async function updateProduct({ data, callback, representativeUuid, supplierUuid }) {
    return (
        await api.post(`/supplier-representative/${representativeUuid}/${supplierUuid}/product`, data, {
            headers: {
                'content-type': 'multipart/form-data',
            },
            onUploadProgress: callback
        })
    );
}