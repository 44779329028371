import { useEffect, useRef, useContext } from 'react';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import { ENUM_SHORTCUT } from 'erva-doce-common';

export function useHotkeys(show, cancelButtonRef, confirmButtonRef) {
    const lastShowRef = useRef(false);
    const { addHotkey, removeHotkey } = useContext(EnvironmentContext);

    useEffect(() => {
        if (show === lastShowRef.current) return;
        lastShowRef.current = show;

        let shortcutCancel;
        let shortcutConfirm;

        function removeHotkeys() {
            removeHotkey(shortcutCancel);
            removeHotkey(shortcutConfirm);
        }

        if (show) {
            shortcutCancel = addHotkey(ENUM_SHORTCUT.SHORTCUT_CANCEL, () => cancelButtonRef.current?.click());
            shortcutConfirm = addHotkey(ENUM_SHORTCUT.SHORTCUT_CONFIRM, () => confirmButtonRef.current?.click());
        } else {
            removeHotkeys();
        }

        return () => {
            removeHotkeys();
        };
    }, [show, addHotkey, removeHotkey, cancelButtonRef, confirmButtonRef]);
}
