import { useState } from 'react';
import ButtonGoto from '../../components/ButtonGoto';
import ButtonRemove from '../../components/ButtonRemove';
import FieldCurrency, { formatCurrency } from '../../components/FieldCurrency';
import { maskPhoneString } from '../../components/FieldPhone';
import FieldQuotationType from '../../components/FieldQuotationType';
import FieldRepresentative from '../../components/FieldRepresentative';
import Picture, { PictureSize, PictureStyle } from '../../components/Picture';
import Table from '../../components/Table';
import { getRepresentativeFormRoute } from '../representatives/RepresentativeForm';
import FieldCollaborator from '../../components/FieldCollaborator';
import Button from '../../components/Button';
import { IconLog } from '../../components/images';
import { useNavigate } from 'react-router';
import { getSupplierRepresentativeProductsRoute } from '../supplier-representative-products';

function RepresentativeRow({
    value,
    onRemove,
    onChange,
    readOnly,
}) {
    const navigate = useNavigate();

    const [representative, setRepresentative] = useState(value);

    function updateSupplier(representative) {
        setRepresentative(representative);
        if (onChange) onChange(representative);
    }

    return (
        <tr>
            <td className={'center'}>
                <Picture
                    picture={representative.picture}
                    name={representative.name}
                    size={PictureSize.NORMAL}
                    style={PictureStyle.USER_ROUNDED}
                />
            </td>
            <td>{representative.name}</td>
            <td>{representative.email ?? ''}</td>
            <td>{maskPhoneString(representative.phone) ?? ''}</td>
            <td>
                <FieldQuotationType
                    label={null}
                    fieldGroup={false}
                    select={representative.quotationType}
                    onSelected={(quotationType) =>
                        updateSupplier({ ...representative, quotationType })
                    }
                    readOnly={readOnly}
                />
            </td>
            <td className={'right'}>
                <FieldCurrency
                    fieldGroup={false}
                    value={representative.minimumOrder}
                    readOnly={readOnly}
                    onChange={({ target }) =>
                        updateSupplier({
                            ...representative,
                            minimumOrder: formatCurrency(target.value),
                        })
                    }
                />
            </td>
            <td>
                <FieldCollaborator
                    fieldGroup={false}
                    multipleSelection={false}
                    onSelected={(responsible) => {
                        updateSupplier({ ...representative, responsible });
                    }}
                    select={representative.responsible}
                    readOnly={readOnly}
                />
            </td>
            <td className={'controls'}>
                {!readOnly && value.id && (
                    <>
                        <Button className={'transparent'} onClick={() => navigate(getSupplierRepresentativeProductsRoute(value.id))}>
                            <img src={IconLog} title={'Listagem de produtos'} alt={'Listagem de produtos'} />
                        </Button>
                        <ButtonGoto
                            title={'Verificar cadastro'}
                            onClick={() => {
                                window.open(
                                    getRepresentativeFormRoute(
                                        representative.uuid
                                    ),
                                    '',
                                    ''
                                );
                            }}
                        />
                    </>
                )}
            </td>
        </tr>
    );
}

function Representatives({
    value = [],
    onChange,
    readOnly,
    supplierUUID,
    ...props
}) {
    const [representatives, setRepresentatives] = useState(value);

    function updateRepresentatives(representatives) {
        setRepresentatives(representatives);
        if (onChange) onChange(representatives);
    }

    function renderTable() {
        const rows = [];
        representatives.forEach((representative, index) => {
            rows.push((
                <RepresentativeRow
                    key={index}
                    value={representative}
                    onRemove={() => updateRepresentatives(representatives.filter((s) => s.uuid !== representative.uuid))}
                    onChange={(data) => {
                        representatives[index] = data;
                        updateRepresentatives(representatives);
                    }}
                    readOnly={readOnly}
                    supplierUUID={supplierUUID}
                />
            ));
        });
        return rows;
    }

    function addRepresentative(representative) {
        updateRepresentatives([...representatives, {
            uuid: representative.id,
            name: representative.value,
            email: representative.email,
            phone: representative.phone,
            picture: representative.picture,
        }]);
    }

    return (
        <>
            {!readOnly && (
                <div className={'gd'}>
                    <div className={'gd-col-6'}>
                        <FieldRepresentative
                            label={'Representante'}
                            multipleSelection={true}
                            onAddItem={(representative) =>
                                addRepresentative(representative)
                            }
                            ignoreItems={representatives.map(
                                (representative) => representative.uuid
                            )}
                        />
                    </div>
                </div>
            )}
            <div className={'gd'}>
                <div className={'gd-col-12'}>
                    <Table
                        columns={[
                            {}, // picture
                            {
                                // I18N
                                name: 'Representante',
                            },
                            {
                                // I18N
                                name: 'E-mail',
                            },
                            {
                                // I18N
                                name: 'Telefone',
                            },
                            {
                                // I18N
                                name: 'Cotação',
                            },
                            {
                                // I18N
                                name: 'Pedido mínimo',
                            },
                            {
                                // I18N
                                name: 'Responsável',
                            },
                            {}, // controls
                        ]}
                    >
                        {renderTable()}
                    </Table>
                </div>
            </div>
        </>
    );
}

export default Representatives;
