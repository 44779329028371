import { useContext, useEffect, useRef, useState } from 'react';
import Button, { ButtonColor, ButtonFontColor } from '../../../components/Button';
import ScreenHeader from '../../../components/logged/ScreenHeader';
import { getDashboardRoute } from '../../../dashboard/Dashboard';
import { EnvironmentContext } from '../../../contexts/EnviromentContext';
import * as ParamsService from '../../../services/ParamsService';
import FieldNumber from '../../../components/FieldNumber';
import FieldText from '../../../components/FieldText';
import FieldCurrency, { extractCurrencyNumber, formatCurrency } from '../../../components/FieldCurrency';
import { formatValue } from '../../../util/formatValue';
import { Editor } from '@tinymce/tinymce-react';
import { ENUM_SHORTCUT } from 'erva-doce-common';

const INITIAL_STATE = {
    maxParcels: '',
    avgDeliveryTime: '',
    frete: '',
    contactEmail: '',
    instagramURL: '',
    facebookURL: '',
    whatsAppURL: '',
    privacyPoliceHTML: '',
};

export default function Parametros() {
    const title = 'Parâmetros';
    const name = 'Editor-ERVA-DOCE';
    const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;
    const { setConfirmModal, setInfoModal, backendConnectionError, addHotkey, removeHotkey } = useContext(EnvironmentContext);

    const [formData, setFormData] = useState(INITIAL_STATE);
    const [formError, setFormError] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const editorRef = useRef(null);

    const updateFormData = (value) => {
        setFormData(state => ({
            ...state,
            ...value,
        }));
    };

    const fetchData = async () => {
        try {
            setIsLoading(true);

            const res = await ParamsService.get();

            setFormData({
                ...res,
                frete: formatValue(`${res?.frete}`),
            });
        } catch (err) {
            console.error(err);
            backendConnectionError('Fail to fetch params', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    const hasValidationErrors = () => {
        let hasError = false;
        setFormError({});

        if (!formData.contactEmail) {
            hasError = true;
            setFormError(state => ({ ...state, contactEmail: 'Preencha corretamente' }));
        }

        return hasError;
    };

    const save = async () => {
        if (hasValidationErrors()) return;

        const proceed = async () => {
            try {
                setIsLoading(true);

                const data = {
                    maxParcels: formData.maxParcels || 0,
                    avgDeliveryTime: formData.avgDeliveryTime || 0,
                    contactEmail: formData.contactEmail,
                    instagramURL: formData.instagramURL,
                    facebookURL: formData.facebookURL,
                    whatsAppURL: formData.whatsAppURL,
                    frete: extractCurrencyNumber(formData.frete || 0),
                    privacyPoliceHTML: editorRef?.current?.getContent(),
                };

                await ParamsService.update(data);

                setInfoModal({
                    title,
                    message: 'Parâmetros salvos com sucesso!',
                    show: true,
                });
            } catch (err) {
                console.log(err);
                backendConnectionError('Fail to save params', err, null, title);
            } finally {
                setIsLoading(false);
            }
        };

        setConfirmModal({
            title: 'Salvar Parâmetros',
            message: 'Deseja realmente salvar os Parâmetros?',
            show: true,
            onConfirm: proceed,
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const shortcutConfirm = addHotkey(ENUM_SHORTCUT.SHORTCUT_CONFIRM, () => {
            save();
        });

        return () => {
            removeHotkey(shortcutConfirm);
        };
    }, []);

    return (
        <div className={'crud-list'}>
            <ScreenHeader
                title={title}
                breadcrumbs={[
                    { name: 'Loja virtual', route: getDashboardRoute() },
                    { name: 'Geral', route: getDashboardRoute() },
                    { name: title, route: getParametrosRoute() },
                ]}
                hideStore
            />
            <div className={'row'}>
                <div className={'col-3'}>
                    <FieldNumber
                        label={'Máximo de parcelas (sem juros)'}
                        onChange={({ target }) => updateFormData({ maxParcels: target.value })}
                        value={formData?.maxParcels}
                        validationError={formError?.maxParcels}
                        thousandsSeparator={false}
                    />
                </div>
                <div className={'col-3'}>
                    <FieldNumber
                        label={'Tempo médio de entrega (em horas)'}
                        onChange={({ target }) => updateFormData({ avgDeliveryTime: target.value })}
                        value={formData?.avgDeliveryTime}
                        validationError={formError?.avgDeliveryTime}
                        thousandsSeparator={false}
                    />
                </div>
                <div className={'col-3'}>
                    <FieldCurrency
                        label={'Valor mínimo para frete grátis'}
                        onChange={({ target }) => updateFormData({ frete: formatCurrency(target.value) })}
                        value={formData?.frete}
                        validationError={formError?.frete}
                    />
                </div>
                <div className={'col-3'}>
                    <FieldText
                        label={'Endereço de e-mail de contato'}
                        type={'email'}
                        onChange={({ target }) => updateFormData({ contactEmail: target.value })}
                        value={formData?.contactEmail}
                        validationError={formError?.contactEmail}
                    />
                </div>
                <div className={'col-3'}>
                    <FieldText
                        label={'Endereço URL para o Instagram'}
                        onChange={({ target }) => updateFormData({ instagramURL: target.value })}
                        value={formData?.instagramURL}
                        validationError={formError?.instagramURL}
                    />
                </div>
                <div className={'col-3'}>
                    <FieldText
                        label={'Endereço URL para o Facebook'}
                        onChange={({ target }) => updateFormData({ facebookURL: target.value })}
                        value={formData?.facebookURL}
                        validationError={formError?.facebookURL}
                    />
                </div>
                <div className={'col-3'}>
                    <FieldText
                        label={'Endereço URL para o WhatsApp'}
                        onChange={({ target }) => updateFormData({ whatsAppURL: target.value })}
                        value={formData?.whatsAppURL}
                        validationError={formError?.whatsAppURL}
                    />
                </div>
            </div>
            <div className={'row'} >
                <div className={'col-12'}>
                    <label>
                        {'Conteúdo de Política de Privacidade'}
                    </label>
                </div>
                <div className={'col-12 p-12'}>
                    <Editor
                        id={name}
                        tagName={name}
                        textareaName={name}
                        apiKey={apiKey}
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        initialValue={formData?.privacyPoliceHTML}
                        init={{
                            language: 'pt_BR',
                            menubar: false,
                            plugins: [
                                'advlist',
                                'autolink',
                                'lists',
                                'link',
                                'charmap',
                                'preview',
                                'anchor',
                                'searchreplace',
                                'visualblocks',
                                'code',
                                'fullscreen',
                                'insertdatetime',
                                'media',
                                'table',
                                'code',
                                'wordcount',
                                'image',
                            ],
                            toolbar:
                                `undo redo | blocks | forecolor backcolor
                                 bold italic | alignleft aligncenter
                                 alignright alignjustify | bullist numlist outdent indent |
                                 removeformat`,
                            color_map: [
                                { '#317c7d': 'Green' },
                            ],
                            enter_br: true,
                            entity_encoding: 'raw',
                            statusbar: false,
                            content_css: '/css/tiny-style.css'
                        }}
                    />

                </div>
            </div>
            <div className={'row mt-24'}>
                <div className={'col-10'}></div>
                <div className={'col-2'}>
                    <Button
                        className={'w-100'}
                        color={ButtonColor.BUTTON_COLOR_GREEN}
                        fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                        onClick={save}
                    >
                        {`Salvar [${ENUM_SHORTCUT.SHORTCUT_CONFIRM}]`}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export function getParametrosRoute() {
    return '/loja-virtual/geral/parametros';
}
