export const IcmsTaxSituationCfeEnum = {
    ICMS_102: "ICMS_102",
    ICMS_500: "ICMS_500",
}


export function IcmsValue(key: string) : string {
    const icms = IcmsTaxSituationCfeEnum[key];
    switch (icms) {
        case "ICMS_102":
            return "102";
        case "ICMS_500":
            return "500";
    }
}


export function IcmsTaxSituationCfeValueByCfop(cfop: string): string {
    switch (cfop) {
        case '5102':
            return IcmsTaxSituationCfeEnum.ICMS_102;
        case '5405':
            return IcmsTaxSituationCfeEnum.ICMS_500;
    }
}