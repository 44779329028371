import React, { forwardRef, useId } from 'react';
import { parseNumberLocale } from '../util/numberParser';
import Field from './Field';
import './FieldCurrency.scss';

const prefix = 'R$';

function FieldCurrency({
    onChange,
    value,
    validationError,
    ...props
}, ref) {
    const reactId = useId();
    const idInput = props.id || reactId;

    return (
        <div className={'input-container'}>
            <Field
                idInput={idInput}
                {...props}
            >
                <input
                    id={idInput}
                    style={validationError ? { border: '1px solid #B92020' } : {}}
                    className={validationError ? 'input-error' : ''}
                    ref={ref}
                    value={value}
                    onChange={onChange}
                    disabled={props.disabled}
                />
                {validationError && <p className={'error-message'} style={{ color: '#B92020',   textAlign: 'center',  fontWeight: '600' }}>{validationError}</p>}
            </Field>
        </div>
    );
}

export default React.forwardRef(FieldCurrency);

export function extractCurrencyNumber(value) {

    const data = { value: value };

    if (typeof value !== 'string') {
        data.value = value.toString();
    }

    const specialCharacter = data.value.match(/^[a-zA-Z]+\$,|_$/);
    if (!value || specialCharacter) return null;

    return parseNumberLocale(data.value.replace(prefix, '').trim());
}

export const formatCurrency = (value, first = false) => {
    const number = value.replace(/([\D])/g, '');
    const amount = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });

    if (first) {
        return `${amount.format(number)}`;
    } else {
        return `${amount.format(number / 100)}`;
    }
};

