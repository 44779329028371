import {
    ENUM_SHORTCUT,
    PermissionCollaboratorEnum,
    PermissionsEnum,
    UserProfileEnum,
    UserProfileText,
    UserStatusEnum,
    UserStatusText,
    ValidationErrorUserText,
    isValidCpf,
    isValidDate,
    isValidEmail,
    isValidName,
    isValidPhone,
} from 'erva-doce-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button, { ButtonColor, ButtonFontColor } from '../../components/Button';
import FieldAddress, { emptyAddressData, getAddressData, getAddressObject } from '../../components/FieldAddress';
import FieldCheckbox from '../../components/FieldCheckbox';
import FieldCpf, { unmaskCpf } from '../../components/FieldCpf';
import FieldDate from '../../components/FieldDate';
import FieldJobTitle from '../../components/FieldJobTitle';
import FieldPhone, { unmaskPhone } from '../../components/FieldPhone';
import FieldStore from '../../components/FieldStore';
import FieldText from '../../components/FieldText';
import FieldTextarea from '../../components/FieldTextarea';
import { Loading, LoadingSize } from '../../components/Loading';
import Picture, { PictureSize } from '../../components/Picture';
import RouteChangePrompt from '../../components/RouteChangePrompt';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as CollaboratorsService from '../../services/CollaboratorsService';
import { getCollaboratorsRoute } from './Collaborators';
import FieldLiveSearchSelectionTable from '../../components/FieldLiveSearchSelectionTable';
import FieldSelect from '../../components/FieldSelect';

export function personalFormDataHasValidationError(formData, updateFormError, useStatusField = true) {
    let hasError = false;
    if (!isValidName(formData.name)) {
        hasError = true;
        // I18N
        updateFormError({ name: 'Digite o nome completo' });
    }
    if (useStatusField && !formData.status) {
        hasError = true;
        // I18N
        updateFormError({ status: 'Selecione o status' });
    }
    if (!isValidCpf(formData.cpf)) {
        hasError = true;
        // I18N
        updateFormError({ cpf: 'Digite um CPF válido' });
    }
    if (!isValidDate(formData.birthday)) {
        hasError = true;
        // I18N
        updateFormError({ birthday: 'Digite uma data válida' });
    }
    if (!isValidEmail(formData.email)) {
        hasError = true;
        // I18N
        updateFormError({ email: 'Digite um e-mail válido' });
    }
    if (!!formData.phone?.trim() && !isValidPhone(formData.phone)) {
        hasError = true;
        // I18N
        updateFormError({ phone: 'Digite um telefone válido' });
    }
    if (!isValidPhone(formData.cellphone)) {
        hasError = true;
        // I18N
        updateFormError({ cellphone: 'Digite um celular válido' });
    }
    return hasError;
}

export function PersonalDataForm({
    formData,
    user,
    formError,
    updateFormData,
    addressData,
    setAddressData,
    onPictureChange,
    useStatusField = true,
    readOnly,
}
) {
    const phoneInputRef = useRef();

    return (
        <fieldset>
            <legend>
                {/*I18N*/}
                {'Dados pessoais'}
            </legend>
            <div className={'gd field-group'}>
                <div className={'gd-col-2 gd-col--center gd-col--middle'}>
                    <Picture
                        picture={formData?.picture}
                        onChange={(imageBase64) => {
                            updateFormData({ picture: imageBase64 });
                            onPictureChange();
                        }}
                        name={formData?.name}
                        size={PictureSize.LARGE}
                        editPicture={!readOnly}
                    />
                </div>
                <div className={'gd-col-5'}>
                    <FieldText
                        // I18N
                        label={'Nome completo'}
                        onChange={({ target }) => updateFormData({ name: target.value })}
                        value={formData?.name}
                        validationError={formError?.name}
                        maxLength={120}
                        readOnly={readOnly}
                    />
                </div>
                {
                    user?.cashier?.id && (
                        <div className={'gd-col-2'}>
                            <FieldText
                                label={'Código de vendedor'}
                                onChange={({ target }) => updateFormData({ sellerId: target.value })}
                                value={formData?.sellerId}
                                disabled={true}
                                readOnly={true}
                            />
                        </div>
                    )
                }
                {
                    useStatusField && (
                        <div className={'gd-col-3'}>
                            <FieldCheckbox
                            // I18N
                                label={'Situação do cadastro'}
                                inputs={(() => {
                                    let inputs = [];
                                    for (const status in UserStatusEnum) {
                                        inputs.push({
                                            value: status,
                                            label: UserStatusText(status),
                                            type: 'radio',
                                            onChange: () => {
                                                updateFormData({ status });
                                            },
                                            checked: formData.status === status
                                        });
                                    }
                                    return inputs;
                                })()}
                                validationError={formError?.status}
                                readOnly={readOnly}
                            />
                        </div>
                    )
                }
            </div>
            <div className={'gd'}>
                <div className={'gd-col-4'}>
                    <FieldCpf
                        // I18N
                        label={'CPF'}
                        onChange={({ target }) => updateFormData({ cpf: target.value })}
                        value={formData?.cpf}
                        validationError={formError?.cpf}
                        readOnly={readOnly}
                    />
                </div>
                <div className={'gd-col-4'}>
                    <FieldDate
                        // I18N
                        label={'Data de nascimento'}
                        onChange={({ target }) => updateFormData({ birthday: target.value })}
                        value={formData?.birthday}
                        validationError={formError?.birthday}
                        readOnly={readOnly}
                    />
                </div>
                <div className={'gd-col-4'}>
                    <FieldText
                        // I18N
                        label={'E-mail'}
                        maxLength={80}
                        onChange={({ target }) => updateFormData({ email: target.value })}
                        value={formData?.email}
                        validationError={formError?.email}
                        readOnly={readOnly}
                    />
                </div>
            </div>
            <div className={'gd'}>
                <div className={'gd-col-4'}>
                    <FieldAddress
                        // I18N
                        label={'Endereço residencial'}
                        onChange={(addressData) => {
                            setAddressData(addressData);
                        }}
                        value={addressData}
                        onClose={() => {
                            phoneInputRef.current?.inputElement?.focus();
                        }}
                        readOnly={readOnly}
                    />
                </div>
                <div className={'gd-col-4'}>
                    <FieldPhone
                        ref={phoneInputRef}
                        // I18N
                        label={'Telefone'}
                        onChange={({ target }) => updateFormData({ phone: target.value })}
                        value={formData?.phone}
                        validationError={formError?.phone}
                        readOnly={readOnly}
                    />
                </div>
                <div className={'gd-col-4'}>
                    <FieldPhone
                        // I18N
                        label={'Celular'}
                        onChange={({ target }) => updateFormData({ cellphone: target.value })}
                        onKeyUp={({ target }) => updateFormData({ cellphone: target.value })}
                        value={formData?.cellphone}
                        validationError={formError?.cellphone}
                        readOnly={readOnly}
                    />
                </div>
            </div>
        </fieldset>
    );
}

export default function CollaboratorForm() {
    const emptyFormData = {
        picture: null,
        name: '',
        status: UserStatusEnum.ACTIVE,
        cpf: '',
        birthday: '',
        email: '',
        phone: '',
        cellphone: '',
        inviteAccepted: false,
        jobTitle: null,
        store: null,
        observation: '',
        profile: '',
    };
    const emptyFormError = {
        ...emptyFormData,
        picture: undefined,
        inviteAccepted: '',
        store: null,
        profile: null,
    };
    const [formData, setFormData] = useState(emptyFormData);
    const [formError, setFormError] = useState(emptyFormError);
    const [addressData, setAddressData] = useState(emptyAddressData);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [resendInviteLoading, setResendInviteLoading] = useState(false);
    const buttonSaveRef = useRef();
    const buttonResendInviteRef = useRef();
    const buttonDeleteRef = useRef();
    const {
        setLoading,
        backendConnectionError,
        setInfoModal,
        setConfirmModal,
        addHotkey,
        removeHotkey,
        user,
    } = useContext(EnvironmentContext);
    const { uuid } = useParams();
    const [loadingCollaborator, setLoadingCollaborator] = useState(!!uuid);
    const [collaboratorName, setCollaboratorName] = useState('');
    const [hasChange, setHasChange] = useState(false);
    const [pictureChanged, setPictureChanged] = useState(false);
    const navigate = useNavigate();

    function updateFormData(data) {
        // noinspection JSCheckFunctionSignatures
        setFormData((formData) => ({ ...formData, ...data }));
        setHasChange(true);
    }

    function hasValidationError() {
        setValidateOnChange(true);
        setFormError(emptyFormError);
        // noinspection JSCheckFunctionSignatures
        let hasError = personalFormDataHasValidationError(
            formData,
            (error) => setFormError((formError) => ({ ...formError, ...error }))
        );

        if (formData?.profile !== UserProfileEnum.ADMIN && !formData.jobTitle) {
            hasError = true;
            setFormError((formError) => ({ ...formError, jobTitle: 'Selecione um cargo/função' }));
        }

        if (formData?.profile !== UserProfileEnum.ADMIN && !formData?.store) {
            hasError = true;
            setFormError((formError) => ({ ...formError, store: 'Selecione uma loja de atuação' }));
        }

        if (!formData?.profile) {
            hasError = true;
            setFormError((formError) => ({ ...formError, profile: 'Selecione um tipo de perfil' }));
        }

        return hasError;
    }

    async function fetchCollaborator() {
        try {
            setLoadingCollaborator(true);
            const collaborator = await CollaboratorsService.getCollaborator(uuid);
            setCollaboratorName(collaborator.name);
            // noinspection JSCheckFunctionSignatures
            setFormData({
                ...collaborator,
                birthday: collaborator.birthday?.split('T')[0],
                jobTitle: collaborator.jobTitle ? { id: collaborator.jobTitle, value: null } : null,
                store: collaborator?.store?.uuid ? { id: collaborator.store.uuid, value: collaborator.store.fantasyName } : null,
                observation: collaborator.observation ?? '',
                profile: collaborator.profile,
            });
            setPictureChanged(false);
            setAddressData(getAddressData(collaborator));
        } catch (e) {
            const title = getTitle();
            const { response } = e;
            if (response?.status === 404) {
                setInfoModal({
                    title,
                    // I18N
                    message: 'Colaborador não encontrado.',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: back,
                });
            } else {
                backendConnectionError('Fail to fetch collaborator', e, null, title);
            }
        } finally {
            setLoadingCollaborator(false);
        }
    }

    useEffect(() => {
        if (uuid) {
            fetchCollaborator();
        }
    }, [uuid]);

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [formData]);

    useEffect(() => {
        if (!loadingCollaborator) setHasChange(false);
    }, [loadingCollaborator]);

    const canSave = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_USERS);
    const canRemove = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_USERS);

    useEffect(() => {
        let shortcutDelete = null;
        let shortcutFilter = null;
        let shortcutConfirm = null;

        if (uuid) {
            if (canRemove) {
                shortcutDelete = addHotkey(ENUM_SHORTCUT.SHORTCUT_DELETE, () => {
                    buttonDeleteRef.current?.click();
                });
            }
            if (canSave) {
                if (!formData.inviteAccepted) {
                    shortcutFilter = addHotkey(ENUM_SHORTCUT.SHORTCUT_REFRESH, () => {
                        buttonResendInviteRef.current?.click();
                    });
                }
            }
        }
        if (canSave) {
            shortcutConfirm = addHotkey(ENUM_SHORTCUT.SHORTCUT_CONFIRM, () => {
                buttonSaveRef.current?.click();
            });
        }
        return () => {
            removeHotkey(shortcutConfirm);
            removeHotkey(shortcutDelete);
            removeHotkey(shortcutFilter);
        };
    }, []);

    function back() {
        navigate(getCollaboratorsRoute());
    }

    async function deleteCollaborator() {
        // I18N
        const title = 'Excluir colaborador';
        async function proceed() {
            try {
                setDeleteLoading(true);
                await CollaboratorsService.deleteCollaborator(uuid);
                // I18N
                const message = 'Colaborador excluído com sucesso!';
                const style = InfoModalStyle.SUCCESS;
                const onClose = back;
                setHasChange(false);
                setInfoModal({
                    title,
                    message,
                    style,
                    show: true,
                    onClose,
                });
            } catch (e) {
                backendConnectionError('Fail to delete user', e, null, title, ValidationErrorUserText);
            } finally {
                setDeleteLoading(false);
            }
        }

        setConfirmModal({
            title,
            // I18N
            message: 'Você tem certeza que deseja excluir o colaborador?',
            onConfirm: proceed,
            show: true,
        });
    }

    async function resendInvite() {
        if (formData.inviteAccepted) return; // avoid hotkey trigger (same condition to show button)

        const title = getTitle();
        try {
            setResendInviteLoading(true);
            await CollaboratorsService.resendInvite(uuid);
            const title = 'Convidar colaborador';
            const message = 'Convite reenviado com sucesso!';
            const style = InfoModalStyle.SUCCESS;
            setHasChange(false);
            setInfoModal({
                title,
                message,
                style,
                show: true,
            });
        } catch (e) {
            backendConnectionError('Fail to invite user', e, null, title, ValidationErrorUserText);
        } finally {
            setResendInviteLoading(false);
        }
    }

    async function save() {
        if (saveLoading) return;
        if (hasValidationError()) return;

        const title = getTitle();

        try {
            setSaveLoading(true);

            const body = {
                ...formData,
                ...getAddressObject(addressData),
                birthday: new Date(formData.birthday),
                cpf: unmaskCpf(formData.cpf),
                phone: unmaskPhone(formData.phone),
                cellphone: unmaskPhone(formData.cellphone),
                picture: pictureChanged ? formData.picture : null,
                jobTitle: formData.jobTitle?.id,
                store: formData.store ? { uuid: formData.store.id } : null,
                profile: formData.profile,
            };

            let message;
            if (!uuid) { // new collaborator
                await CollaboratorsService.inviteCollaborator(body);
                // I18N
                message = 'Um convite foi enviado para o e-mail do colaborador.';
            } else {
                await CollaboratorsService.editCollaborator(uuid, body);
                // I18N
                message = 'Colaborador editado com sucesso!';
            }

            let style = InfoModalStyle.SUCCESS;
            let onClose = back;

            setHasChange(false);
            setInfoModal({
                title,
                message,
                style,
                show: true,
                onClose,
            });
        } catch (e) {
            backendConnectionError('Fail to invite user', e, null, title, ValidationErrorUserText);
        } finally {
            setSaveLoading(false);
        }
    }

    useEffect(() => {
        const loading = saveLoading || resendInviteLoading || deleteLoading;
        setLoading(loading, true);
    }, [saveLoading, resendInviteLoading, deleteLoading]);

    function getTitle(windowTitle = false) {
        if (uuid) {
            // I18N
            let title = 'Editar colaborador';
            if (windowTitle && collaboratorName) title += ` - ${collaboratorName}`;
            return title;
        } else {
            return 'Novo colaborador';
        }
    }

    const handleProfileOptions = () => {
        const options = [];
        const exclude = [];

        for (const option in UserProfileEnum) {
            if (!exclude.includes(option)) {
                options.push({
                    id: option,
                    value: UserProfileText(option),
                });
            }
        }

        return options;
    };

    function renderForm() {
        if (loadingCollaborator) {
            return (
                <Loading
                    size={LoadingSize.LARGE}
                />
            );
        }

        return (
            <>
                <div>
                    <PersonalDataForm
                        formData={formData}
                        formError={formError}
                        updateFormData={updateFormData}
                        addressData={addressData}
                        setAddressData={setAddressData}
                        onPictureChange={() => setPictureChanged(true)}
                        readOnly={!canSave}
                    />
                    <fieldset>
                        <legend>
                            {'Dados da loja'}
                        </legend>
                        <div className={'gd'}>
                            <div className={'gd-col-4'}>
                                <FieldStore
                                    label={'Loja de atuação'}
                                    select={formData.store}
                                    onSelected={(store) => {
                                        updateFormData({
                                            store,
                                        });
                                    }}
                                    validationError={formError.store}
                                    readOnly={!canSave}
                                />
                            </div>
                            <div className={'gd-col-4'}>
                                <FieldSelect
                                    label={'Tipo de perfil'}
                                    options={handleProfileOptions()}
                                    onChange={({ target }) => {
                                        let profile = target.value;
                                        let jobTitle = formData.jobTitle;

                                        if (target === '-1') {
                                            profile = null;
                                        }

                                        if (profile === UserProfileEnum.ADMIN) {
                                            jobTitle = null;
                                        }

                                        updateFormData({ profile, jobTitle });
                                    }}
                                    value={formData?.profile || '-1'}
                                    validationError={formError?.profile}
                                    placeholder={'Selecione'}
                                    disableDefaultOption={false}
                                />
                            </div>
                            {
                                formData.profile !== UserProfileEnum.ADMIN && (
                                    <div className={'gd-col-4'}>
                                        <FieldJobTitle
                                            select={formData.jobTitle}
                                            onSelected={(value) => updateFormData({ jobTitle: value })}
                                            validationError={formError.jobTitle}
                                            readOnly={!canSave}
                                        />
                                    </div>
                                )
                            }
                        </div>
                        <div className={'gd'}>
                            <div className={'gd-col-12'}>
                                <FieldTextarea
                                    // I18N
                                    label={'Observações gerais'}
                                    value={formData.observation}
                                    onChange={({ target }) => updateFormData({ observation: target.value })}
                                    validationError={formError.observation}
                                    readOnly={!canSave}
                                />
                            </div>
                        </div>
                    </fieldset>
                </div>

                <div className={'controls'}>
                    <div className={'gd'}>
                        <div className={'gd-col gd-col-2'}>
                            {(uuid && !formData.inviteAccepted && canSave) && (
                                <Button
                                    ref={buttonResendInviteRef}
                                    color={ButtonColor.BUTTON_COLOR_GRAY}
                                    loading={resendInviteLoading}
                                    onClick={resendInvite}
                                >
                                    {`Reenviar convite [${ENUM_SHORTCUT.SHORTCUT_REFRESH}]`}
                                </Button>
                            )}
                        </div>
                        <div className={`gd-col ${canSave ? 'gd-col-6' : 'gd-col-8'}`} />
                        <div className={'gd-col gd-col-2'}>
                            {(uuid && canRemove) && (
                                <Button
                                    ref={buttonDeleteRef}
                                    color={ButtonColor.BUTTON_COLOR_RED}
                                    loading={deleteLoading}
                                    onClick={deleteCollaborator}
                                >
                                    {`Excluir [${ENUM_SHORTCUT.SHORTCUT_DELETE}]`}
                                </Button>
                            )}
                        </div>
                        {canSave && (
                            <div className={'gd-col gd-col-2'}>
                                <Button
                                    ref={buttonSaveRef}
                                    color={ButtonColor.BUTTON_COLOR_GREEN}
                                    fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                    loading={saveLoading}
                                    onClick={save}
                                >
                                    {`${!uuid ? 'Convidar' : 'Salvar'} [${ENUM_SHORTCUT.SHORTCUT_CONFIRM}]`}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }

    const title = getTitle();

    return (
        <>
            <RouteChangePrompt enabled={hasChange} />
            <div className={'crud-form'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        // I18N
                        { name: 'Administrativo', route: getDashboardRoute() },
                        // I18N
                        { name: 'Colaboradores', route: getCollaboratorsRoute() },
                        { name: uuid ? (loadingCollaborator ? '...' : collaboratorName) : title },
                    ]}
                    backRoute={getCollaboratorsRoute()}
                    hideStore
                >
                </ScreenHeader>

                {renderForm()}
            </div>
        </>
    );
}

export function getCollaboratorFormRoute(uuid) {
    // I18N
    if (uuid) {
        return `/administrativo/colaboradores/${uuid}`;
    } else {
        return '/administrativo/colaboradores/novo';
    }
}
