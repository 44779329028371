import {
    ENUM_SHORTCUT,
    PermissionsEnum,
    ValidationErrorTaxClassificationText,
} from 'erva-doce-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button, { ButtonColor, ButtonFontColor } from '../../components/Button';
import FieldInteger from '../../components/FieldInteger';
import FieldText from '../../components/FieldText';
import { Loading, LoadingSize } from '../../components/Loading';
import RouteChangePrompt from '../../components/RouteChangePrompt';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as TaxesClassificationsService from '../../services/TaxesClassificationsService';
import { getTaxesClassificationsRoute } from './TaxesClassifications';

export default function TaxClassificationForm() {
    const emptyFormData = {
        description: '',
        code: '',
    };
    const emptyFormError = emptyFormData;
    const [formData, setFormData] = useState(emptyFormData);
    const [formError, setFormError] = useState(emptyFormError);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const buttonDeleteRef = useRef();
    const buttonSaveRef = useRef();
    const {
        setLoading,
        backendConnectionError,
        setInfoModal,
        setConfirmModal,
        addHotkey,
        removeHotkey,
        user,
    } = useContext(EnvironmentContext);
    const { uuid } = useParams();
    const [loadingTaxClassification, setLoadingClassification] = useState(!!uuid);
    const [taxClassificationDescription, setTaxClassificationDescription] = useState('');
    const [hasChange, setHasChange] = useState(false);
    const navigate = useNavigate();

    const canSave = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_FISCAL);
    const canRemove = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_FISCAL);

    function updateFormData(data) {
        // noinspection JSCheckFunctionSignatures
        setFormData((formData) => ({ ...formData, ...data }));
        setHasChange(true);
    }

    async function fetchTaxClassification() {
        try {
            setLoadingClassification(true);
            const taxesClassifications = await TaxesClassificationsService.getTaxClassification(uuid);
            setTaxClassificationDescription(taxesClassifications.description);
            setFormData({
                ...taxesClassifications
            });
        } catch (e) {
            const title = getTitle();
            const { response } = e;
            if (response?.status === 404) {
                setInfoModal({
                    title,
                    // I18N
                    message: 'Classificação fiscal não encontrado.',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: back,
                });
            } else {
                backendConnectionError('Fail to fetch tax classification', e, null, title);
            }
        } finally {
            setLoadingClassification(false);
        }
    }

    useEffect(() => {
        if (uuid) {
            // noinspection JSIgnoredPromiseFromCall
            fetchTaxClassification();
        }
    }, [uuid]);

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [formData]);

    function hasValidationError() {
        let hasError = false;
        setFormError(emptyFormError);
        setValidateOnChange(true);

        if (!formData.description?.trim()) {
            hasError = true;
            // I18N
            setFormError((formError) => ({ ...formError, description: 'Digite a descrição da classificação fiscal' }));
        }

        if (!formData.code?.trim()) {
            hasError = true;
            // I18N
            setFormError((formError) => ({ ...formError, code: 'Digite o código da classificação fiscal' }));
        }

        return hasError;
    }

    useEffect(() => {
        let shortcutDelete;
        let shortcutConfirm;

        if (uuid) {
            if (canRemove) {
                shortcutDelete = addHotkey(ENUM_SHORTCUT.SHORTCUT_DELETE, () => {
                    buttonDeleteRef.current?.click();
                });
            }
        }
        if (canSave) {
            shortcutConfirm = addHotkey(ENUM_SHORTCUT.SHORTCUT_CONFIRM, () => {
                buttonSaveRef.current?.click();
            });
        }
        return () => {
            removeHotkey(shortcutDelete);
            removeHotkey(shortcutConfirm);
        };
    }, []);

    function back() {
        navigate(getTaxesClassificationsRoute());
    }

    async function save() {
        if (saveLoading) return;
        if (hasValidationError()) return;

        const title = getTitle();
        try {
            setSaveLoading(true);
            const body = {
                description: formData.description.trim(),
                code: formData.code.trim()
            };

            let message;
            if (!uuid) { // new tax classification
                await TaxesClassificationsService.addTaxClassification(body);
                // I18N
                message = 'Classificação fiscal salva com sucesso!';
            } else {
                // I18N
                await TaxesClassificationsService.editTaxClassification(uuid, body);
                message = 'Classificação fiscal editada com sucesso!';
            }

            let style = InfoModalStyle.SUCCESS;
            let onClose = back;

            setHasChange(false);
            setInfoModal({
                title,
                message,
                style,
                show: true,
                onClose,
            });
        } catch (e) {
            backendConnectionError('Fail to create/edit classificação fiscal', e, null, title, ValidationErrorTaxClassificationText);
        } finally {
            setSaveLoading(false);
        }
    }

    async function deleteTaxClassification() {
        // I18N
        let title = 'Excluir classificação fiscal';
        async function proceed() {
            try {
                setDeleteLoading(true);
                await TaxesClassificationsService.deleteTaxClassification(uuid);
                // I18N
                const message = 'Classificação fiscal excluída com sucesso!';
                const style = InfoModalStyle.SUCCESS;
                const onClose = back;
                setHasChange(false);
                setInfoModal({
                    title,
                    message,
                    style,
                    show: true,
                    onClose,
                });
            } catch (e) {
                backendConnectionError('Fail to delete tax classification', e, null, title, ValidationErrorTaxClassificationText);
            } finally {
                setDeleteLoading(false);
            }
        }

        setConfirmModal({
            title,
            // I18N
            message: 'Você tem certeza que deseja excluir a classificação fiscal?',
            onConfirm: proceed,
            show: true,
        });
    }

    useEffect(() => {
        setLoading(saveLoading, true);
    }, [saveLoading]);

    function getTitle(windowTitle = false) {
        if (uuid) {
            // I18N
            let title = 'Editar classificação fiscal';
            if (windowTitle && taxClassificationDescription) title += ` - ${taxClassificationDescription}`;
            return title;
        } else {
            return 'Nova classificação fiscal';
        }
    }

    const renderForm = () => {
        if (loadingTaxClassification) {
            return (
                <Loading
                    size={LoadingSize.LARGE}
                />
            );
        }

        return (
            <>
                <div>
                    <fieldset>
                        <legend>
                            {/*I18N*/}
                            {'Dados'}
                        </legend>
                        <div className={'gd field-group'}>
                            <div className={'gd-col-8'}>
                                <FieldText
                                    // I18N
                                    label={'Descrição'}
                                    onChange={({ target }) => updateFormData({ description: target.value })}
                                    value={formData?.description}
                                    validationError={formError?.description}
                                    maxLength={120}
                                    readOnly={!canSave}
                                />
                            </div>
                            <div className={'gd-col-3'}>
                                <FieldInteger
                                    // I18N
                                    label={'Código'}
                                    onChange={({ target }) => updateFormData({ code: target.value })}
                                    value={formData?.code}
                                    validationError={formError?.code}
                                    maxLength={8}
                                    readOnly={!canSave}
                                />
                            </div>
                        </div>
                    </fieldset>
                </div>

                <div className={'controls'}>
                    <div className={'gd'}>
                        <div className={`gd-col ${canSave ? 'gd-col-8' : 'gd-col-10'}`} />
                        <div className={'gd-col gd-col-2'}>
                            {(uuid && canRemove) && (
                                <Button
                                    ref={buttonDeleteRef}
                                    color={ButtonColor.BUTTON_COLOR_RED}
                                    loading={deleteLoading}
                                    onClick={deleteTaxClassification}
                                >
                                    {`Excluir [${ENUM_SHORTCUT.SHORTCUT_DELETE}]`}
                                </Button>
                            )}
                        </div>
                        {canSave && (
                            <div className={'gd-col gd-col-2'}>
                                <Button
                                    ref={buttonSaveRef}
                                    color={ButtonColor.BUTTON_COLOR_GREEN}
                                    fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                    loading={saveLoading}
                                    onClick={save}
                                >
                                    {`Salvar [${ENUM_SHORTCUT.SHORTCUT_CONFIRM}]`}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    };

    const title = getTitle();
    return (
        <>
            <RouteChangePrompt enabled={hasChange} />
            <div className={'crud-form'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Administrativo', route: getDashboardRoute() },
                        { name: 'Classificações fiscais', route: getTaxesClassificationsRoute() },
                        { name: uuid ? (loadingTaxClassification ? '...' : taxClassificationDescription) : title },
                    ]}
                    backRoute={getTaxesClassificationsRoute()}
                    hideStore
                >
                </ScreenHeader>

                {renderForm()}
            </div>
        </>
    );
}

export function getTaxClassificationFormRoute(uuid) {
    // I18N
    if (uuid) {
        return `/fiscal/classificacoes-fiscais/${uuid}`;
    } else {
        return '/fiscal/classificacoes-fiscais/novo';
    }
}
